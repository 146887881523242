import {
  Draft,
  castDraft,
  castImmutable,
  current,
  original,
  produce,
} from 'immer'
import { Action } from 'redux'
import { EntityID, EntityType } from '../entities/Identificable'
import {
  Operation,
  OperationVerb,
  isOperation,
  isOperationType,
} from '../entities/Operation'
import { UserId, buildSID } from '../entities/SID'
import { VectorClock, compare, mergeClocks } from '../entities/VectorClock'
import { Actor } from '../services/User'
import { PriorityQueue } from '../utils/PriorityQueue'
import {
  AppOperation,
  AppState,
  ProjectState,
  getMetaProjectId,
  getProjectState,
} from './AppOperation'
import { clockCoversOperation } from './ClockManager'
import { buildOperation } from './OperationHandler'
import {
  Project,
  createProject,
  projectReducer,
  recordOperation,
} from './Project'

// type RootAppReducer = (state: AppState, operation: AppOperation) => AppState

export type AppAction = AppOperation | Action

export const operationReducer = produce<AppState, [AppAction]>(
  (state, operation) => {
    try {
      if (isOperation(operation)) {
        if (isOperationType(operation.type, OperationVerb.Boop)) {
          return
        } else if (isOperationType(operation.type, OperationVerb.Resync)) {
          mutatingProcessOperation(state, operation)
        } else {
          const holderPath = operation.holderId.toRelativeId()
          const { clock, operationQueue } = state.projects[holderPath]
          const queue = new PriorityQueue<AppOperation>(
            operationQueueComparator,
            operationQueue as AppOperation[],
          )
          queue.push(operation)

          while (clockCoversOperation(clock, queue.root)) {
            const nextOperation = queue.pop() as AppOperation
            const operationPath = nextOperation.id.toRelativeId()
            if (state.projects[holderPath].history.entries[operationPath]) {
              // if existing in history, we mostly ignore it
              if (nextOperation.seq) {
                // but update the 'seq' in case it came from the server
                state.projects[holderPath].history.entries[
                  operationPath
                ].content.seq = nextOperation.seq
                if (state.projects[holderPath].latestSeq < nextOperation.seq) {
                  state.projects[holderPath].latestSeq = nextOperation.seq
                }
              }
            } else {
              recordOperation(state.projects[holderPath], nextOperation)
              // const transformedOperation = otTransform(nextOperation) // COT-DO
              mutatingProcessOperation(state, nextOperation)
              clock[nextOperation.id.sid] =
                (clock[nextOperation.id.sid] ?? 0) + 1
            }
          }
        }
      } else {
        processAction(state, operation)
      }
    } catch (error) {
      console.error('Error reducing: ', operation, error)
    }
  },
)

// function otTransform(operation: Operation): Operation {
//   return operation
// }

function mutatingProcessOperation(
  draft: Draft<AppState>,
  operation: AppOperation,
): AppState | void {
  if (!operation) {
    return undefined
  }

  switch (operation.type) {
    case 'create:project': {
      const id = operation.id.withDomain(EntityType.Project)
      draft.projects[id.toRelativeId()] = castDraft(
        createProject(id, operation.payload.name, operation.date),
      )
      break
    }
    case 'create:layout':
    case 'create:document': {
      const projectPath = operation.holderId.toRelativeId()
      const project = (draft.projects[projectPath] as unknown) as
        | Project
        | undefined
      if (!project) {
        return undefined
      }

      draft.projects[projectPath] = castDraft(
        projectReducer(project, operation),
      )
      break
    }
    case 'resync:project': {
      console.log('[resync:project]', operation.payload.data.name, operation)
      const project = operation.payload.data
      const projectPath = project.id.toRelativeId()

      if (!draft.projects[projectPath]) {
        draft.projects[projectPath] = castDraft(project)
      } else if (
        draft.projects[projectPath].latestSnapshotId !==
        project.latestSnapshotId
      ) {
        draft.projects[projectPath].clock = mergeClocks(
          castImmutable(draft.projects[projectPath].clock),
          project.clock,
        )
        draft.projects[projectPath].created = project.created
        draft.projects[projectPath].latestSeq = project.latestSeq
        draft.projects[projectPath].latestSnapshotId = project.latestSnapshotId
        draft.projects[projectPath].name = project.name
        for (const documentPath in project.documents) {
          draft.projects[projectPath].documents[documentPath] =
            project.documents[documentPath]
        }

        if (draft.projects[projectPath].history.first !== null) {
          const operationsToReapply: AppOperation[] = []
          const oldEntries = original(
            draft.projects[projectPath].history.entries,
          )

          for (const operationPath in oldEntries) {
            if (!project.history.entries[operationPath]) {
              operationsToReapply.push(
                oldEntries[operationPath].content as AppOperation,
              )
            }
          }

          const queue = new PriorityQueue<AppOperation>(
            operationQueueComparator,
            draft.projects[projectPath].operationQueue as AppOperation[],
          )
          // TODO: Hay una situación de fallo por la que si tenemos que re-enviar
          //       una operación que ya ha sido secuenciada, el servidor va a
          //       ignorarla y se va a perder para siempre
          operationsToReapply.forEach((operation) => queue.push(operation))
          draft.outbox.push(...castDraft(operationsToReapply))
        }
        draft.projects[projectPath].history = project.history
      } else {
        const clockComparison = compare(
          draft.projects[projectPath].clock,
          project.clock,
        )
        if (clockComparison !== 0) {
          // Sending operations not received by the server
          draft.outbox.push(
            ...selectActionsToResend(
              current(draft.projects[projectPath]) as Project,
              draft.currentActor,
              project.clock,
            ),
          )
          const localHistory = draft.projects[projectPath].history

          // Incorporating operations
          const queue = new PriorityQueue<AppOperation>(
            operationQueueComparator,
            draft.projects[projectPath].operationQueue as AppOperation[],
          )
          for (const operationPath in project.history.entries) {
            if (!localHistory.entries[operationPath]) {
              queue.push(
                project.history.entries[operationPath].content as AppOperation,
              )
            }
          }
          project.operationQueue.forEach((operation) => {
            if (!localHistory.entries[operation.id.toRelativeId()]) {
              queue.push(operation as AppOperation)
            }
          })
        } else {
          console.info(`Ignoring 'resync:project' due to no change`)
        }
      }
      break
    }
    case 'resync:user': {
      console.log('[resync:user]', operation.payload)
      const { projects: states, user: actor } = operation.payload

      if (draft.currentActor.id === actor.id) {
        draft.currentActor.currentSite = actor.currentSite
        draft.currentActor.sites = actor.sites
        draft.currentActor.subscription = actor.subscription
        for (const projectId in actor.permissions) {
          draft.currentActor.permissions[projectId] =
            actor.permissions[projectId]
        }

        const existingProjects = Object.keys(current(draft.projects))
        const toResend = existingProjects.filter(
          (projectPath) => !states[projectPath],
        )
        const metaPath = getMetaProjectId(actor.id).toRelativeId()
        toResend.forEach((projectPath) => {
          if (draft.projects[metaPath].history.entries[projectPath]) {
            draft.outbox.push(
              draft.projects[metaPath].history.entries[projectPath].content,
            )
          }
          if (draft.projects[projectPath]) {
            draft.outbox.push(
              ...filterHistoryByAuthor(
                original(draft.projects[projectPath]) as Project,
                actor.id,
                actor.currentSite,
              ),
            )
          }
        })

        // Boop the rest of the projects
        const data: Record<string, ProjectState | null> = {}
        Object.keys(states).forEach((projectPath) => {
          const project = draft.projects[projectPath]
          const remoteState = states[projectPath]
          if (!project || !remoteState) {
            data[projectPath] = null
          } else {
            if (
              project.latestSnapshotId !== remoteState.snapshot ||
              project.latestSeq !== remoteState.seq
            ) {
              data[projectPath] = getProjectState(original(project))
            }
            draft.outbox.push(
              ...selectActionsToResend(
                current(project) as Project,
                draft.currentActor,
                remoteState.clock,
              ),
            )
          }
        })
        if (Object.keys(data).length) {
          draft.outbox.push(
            buildOperation(
              'boop:project',
              current(draft.currentActor),
              current(draft.projects[metaPath]) as Project,
              { data },
            ),
          )
        }
      } else {
        draft.currentActor = actor
      }
      break
    }
  }
}

// type AppStateReducer = OperationReducer<AppState, AppOperationPayload>
// export const appStateReducer: AppStateReducer = produce<
//   AppState,
//   [AppOperation]
// >(mutatingProcessOperation)

function processAction(draft: Draft<AppState>, action: Action): void {
  switch (action.type) {
    case 'empty-outbox':
      draft.outbox = []
      break
    default:
      console.info(
        'Trying to reduce unrecognized non-operation action:',
        action,
      )
  }
}

function selectActionsToResend(
  project: Project,
  actor: Actor,
  targetClock: VectorClock,
): Operation[] {
  const sid = buildSID(actor.id, actor.currentSite)
  const localNs = project.clock[sid]
  const localHistory = project.history
  const targetNs = (targetClock[sid] ?? 0) + 1

  const result: Operation[] = []
  for (let ns = targetNs; ns <= localNs; ns++) {
    const operationPath = new EntityID(
      ns,
      EntityType.Operation,
      actor.currentSite,
      actor.id,
    ).toRelativeId()
    const entry = localHistory.entries[operationPath]
    if (entry) {
      result.push(entry.content)
    }
  }
  return result
}

function filterHistoryByAuthor(
  project: Project,
  userId: UserId,
  siteId: string,
): Operation[] {
  const sid = buildSID(userId, siteId)
  const targetNs = project.clock[sid] ?? 0
  const result: Operation[] = []
  for (let ns = 0; ns <= targetNs; ns++) {
    const operationPath = new EntityID(
      ns,
      EntityType.Operation,
      siteId,
      userId,
    ).toRelativeId()
    if (project.history.entries[operationPath]) {
      result.push(project.history.entries[operationPath].content)
    }
  }
  return result
}

export function operationQueueComparator(
  lhs: AppOperation,
  rhs: AppOperation,
): -1 | 0 | 1 {
  const comparation = compare(lhs.context, rhs.context)
  if (comparation !== 0) {
    return comparation
  } else {
    return lhs.id.sid < rhs.id.sid
      ? 1
      : lhs.id.sid > rhs.id.sid
      ? -1
      : lhs.id.ns < rhs.id.ns
      ? 1
      : lhs.id.ns > rhs.id.ns
      ? -1
      : 0
  }
}
