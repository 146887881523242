import { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  FileDocument,
  FileDocumentType,
  Folder,
} from '../../../core/services/FileElement'
import FolderIcon from '../../icons/FolderIcon'
import DocumentGuiCard from './DocumentGuiCard'

interface FolderViewProps {
  children: (Folder | FileDocument)[]
  isProject?: boolean
}

const FolderView: FC<FolderViewProps> = ({ children, isProject }) => {
  const [folders, documents] = useMemo<[Folder[], FileDocument[]]>(
    () => [
      children
        .filter(
          (doc): doc is Folder => doc.documentType === FileDocumentType.Folder,
        )
        .sort(({ name: lhs }, { name: rhs }) => lhs.localeCompare(rhs)),
      children
        .filter(
          (doc): doc is FileDocument =>
            doc.documentType !== FileDocumentType.Folder,
        )
        .sort(({ name: lhs }, { name: rhs }) => lhs.localeCompare(rhs)),
    ],
    [children],
  )

  return (
    <div className="p-4 space-y-4">
      {!!folders.length && (
        <div>
          <div className="tpg-menu-2 text-opacity-70 flex flex-row items-center">
            <div className="w-8 h-8">
              <FolderIcon className="w-6 h-6 m-1" />
            </div>
            <h6>
              <FormattedMessage
                defaultMessage="Folders"
                id="app.folder-view.section-folders"
              />
            </h6>
          </div>
          <ul className="grid grid-cols-cards-48 gap-4 py-2">
            {folders.map((doc) => (
              <DocumentGuiCard document={doc} key={doc.id.toString()} />
            ))}
          </ul>
        </div>
      )}
      {!!documents.length && (
        <div>
          <div className="tpg-menu-2 text-opacity-70 flex flex-row items-center">
            <div className="w-8 h-8">
              <FolderIcon className="w-6 h-6 m-1" />
            </div>
            <h6>
              <FormattedMessage
                defaultMessage="Documents"
                id="app.folder-view.section-documents"
              />
            </h6>
          </div>
          <ul className="grid grid-cols-cards-48 gap-4 py-2">
            {documents.map((doc) => (
              <DocumentGuiCard document={doc} key={doc.id.toString()} />
            ))}
          </ul>
        </div>
      )}
      {!folders.length && !documents.length && (
        <div className="flex flex-row items-center justify-center pt-24">
          <div className="w-96 h-96 p-16 mx-auto bg-secondary bg-opacity-10 rounded-full">
            <div className="w-64 h-64 flex flex-col justify-center space-y-2">
              <h6 className="tpg-title-6 text-center">
                {isProject ? (
                  <FormattedMessage
                    defaultMessage="This project is empty"
                    id="app.folder-view.empty-project-header"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="This folder is empty"
                    id="app.folder-view.empty-folder-header"
                  />
                )}
              </h6>
              <p className="tpg-menu-2 text-center">
                <FormattedMessage
                  defaultMessage="Add some files using the ''{newDocButton}'' button"
                  id="app.folder-view.empty-folder-body"
                  values={{
                    newDocButton: (
                      <span className="tpg-menu-1 font-display">
                        <FormattedMessage
                          defaultMessage="New"
                          id="app.new-document-fab"
                        />
                      </span>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FolderView
