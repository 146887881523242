import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import useBreakpointQuery, {
  ResponsiveBreakpoint,
} from '../../utils/useBreakpointQuery'

export interface SidebarState {
  isFixed: boolean
  isFullWidth: boolean
  isOpen: boolean
  setBreakpointToFix: (breakpoint: ResponsiveBreakpoint) => void
  setFullWidth: (isFull: boolean) => void
  toggle: () => void
}

const SidebarStateContext = createContext<SidebarState | undefined>(undefined)

export const SidebarStateProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [breakpointToFix, setBreakpointToFix] = useState<ResponsiveBreakpoint>(
    'lg',
  )
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false)
  console.log(isFullWidth ? 'is full width' : 'is NOT full width')
  const overBreakpoint = useBreakpointQuery(breakpointToFix)

  const isFixed = !isFullWidth && overBreakpoint

  const setFullWidth = useCallback((isFull: boolean) => {
    setIsOpen(!isFull)
    setIsFullWidth(isFull)
  }, [])

  const toggle = useCallback(() => {
    if (isFixed) {
      return
    }
    setIsOpen((value) => !value)
  }, [isFixed])

  const value = useMemo<SidebarState>(
    () => ({
      isFixed,
      isFullWidth,
      isOpen: isOpen || isFixed,
      setBreakpointToFix,
      setFullWidth,
      toggle,
    }),
    [isFixed, isFullWidth, isOpen, setFullWidth, toggle],
  )
  return (
    <SidebarStateContext.Provider value={value}>
      {children}
    </SidebarStateContext.Provider>
  )
}

export function useSidebarState(): SidebarState {
  const context = useContext(SidebarStateContext)
  if (typeof context === 'undefined') {
    throw new TypeError(
      `'useSidebarState' must be used within a 'SidebarStateProvider'`,
    )
  }
  return context
}
// export function useSidebarState(
//   breakpointToFix: ResponsiveBreakpoint,
// ): SidebarState {
//   const [isOpen, setIsOpen] = useState<boolean>(true)
//   const isFixed = useBreakpointQuery(breakpointToFix)
//   const toggle = useCallback(() => {
//     if (isFixed) {
//       return
//     }
//     setIsOpen((value) => !value)
//   }, [isFixed])

//   return useMemo<SidebarState>(
//     () => ({
//       isFixed,
//       isOpen: isOpen || isFixed,
//       toggle,
//     }),
//     [isFixed, isOpen, toggle],
//   )
// }
