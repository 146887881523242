import { Draft, produce } from 'immer'
import { DataSource } from '../entities/DataSource'
import {
  EntityID,
  EntityType,
  RelativeEntityID,
} from '../entities/Identificable'
import { Layout, createLayout } from '../entities/Layout'
import {
  LayoutComponent,
  createRootComponent,
} from '../entities/LayoutComponent'
import {
  Operation,
  OperationReducer,
  TypedOperation,
} from '../entities/Operation'
import { VectorClock } from '../entities/VectorClock'
import {
  DocumentID,
  FileDocument,
  FileDocumentType,
  Folder,
  createDocument,
} from '../services/FileElement'

export type ProjectID = EntityID<EntityType.Project>

export interface HistoryEntry {
  readonly content: Operation
  readonly next: RelativeEntityID | null
  readonly prev: RelativeEntityID | null
}

type EntriesRecord = Readonly<Record<string, HistoryEntry>>

export interface ProjectHistory {
  readonly entries: EntriesRecord
  readonly first: RelativeEntityID | null
  readonly latest: RelativeEntityID | null
}

type DocumentsRecord = Readonly<Record<string, FileDocument | Folder>>
// type EntitiesData = {
//   readonly dataSources: Record<string, DataSource>
//   readonly layoutComponents: Record<string, LayoutComponent>
//   readonly layouts: Record<string, Layout>
// }
type EntitiesData = {
  [E in DataSource | Layout | LayoutComponent as E['id']['domain']]: Record<
    RelativeEntityID<E['id']['domain']>,
    E
  >
}

export interface Project {
  readonly clock: VectorClock
  readonly created: string // ISO Timestamp
  readonly documents: DocumentsRecord
  readonly entities: EntitiesData
  readonly history: ProjectHistory
  readonly id: ProjectID
  readonly latestSeq: number
  readonly latestSnapshotId: string
  readonly name: string
  readonly operationQueue: readonly Operation[]
}

export interface ProjectOperationPayload {
  'create:document': {
    readonly documentType: FileDocumentType
    // holderId: EntityID<EntityType.Project>
    // id: DocumentID
    readonly name: string
    readonly parentId: DocumentID | null
  }
  'create:layout': {
    readonly documentType: FileDocumentType.Layout
    // holderId: EntityID<EntityType.Project>
    // id: DocumentID
    readonly name: string
    readonly parentId: DocumentID | null
  }
  'update:document': {
    // holderId: EntityID<EntityType.Project>
    readonly id: DocumentID
  }
  // 'resync:document': undefined
}

export function createProject(
  id: ProjectID,
  name: string,
  date: string,
): Project {
  const latestSnapshotId = buildSnapshotID(date)
  return Object.freeze({
    clock: Object.freeze({ [id.sid]: 0 }),
    created: date,
    documents: {},
    entities: {
      [EntityType.DataSource]: {},
      [EntityType.Layout]: {},
      [EntityType.LayoutComponent]: {},
    },
    history: createProjectHistory(),
    id,
    latestSeq: 0,
    latestSnapshotId,
    name,
    operationQueue: Object.freeze([]),
  })
}

// type ProjectReducer = (state: Project, action: ProjectAction) => Project
type ProjectReducer = OperationReducer<Project, ProjectOperationPayload>
export const projectReducer: ProjectReducer = produce(
  (draft: Draft<Project>, operation): Project | void => {
    switch (operation.type) {
      case 'create:document': {
        if (operation.payload.documentType === FileDocumentType.Folder) {
          draft.documents[operation.id.toRelativeId()] = createDocument(
            operation.id.withDomain(EntityType.Document),
            operation.payload.name,
            operation.payload.parentId,
            operation.date,
            operation.payload.documentType,
            operation.holderId,
          )
        } else {
          draft.documents[operation.id.toRelativeId()] = createDocument(
            operation.id.withDomain(EntityType.Document),
            operation.payload.name,
            operation.payload.parentId,
            operation.date,
            operation.payload.documentType,
            operation.holderId,
          )
        }
        break
      }
      case 'create:layout': {
        const entityPath = operation.id.toRelativeId()
        const rootComponentId = operation.id.withDomain(
          EntityType.LayoutComponent,
        )
        const layoutId = operation.id.withDomain(EntityType.Layout)
        const rootComponent = createRootComponent(rootComponentId, layoutId)
        const layout = createLayout(
          operation.id.withDomain(EntityType.Layout),
          rootComponentId,
        )
        const document = createDocumentFromOperation(operation, layoutId)
        draft.documents[entityPath] = document
        draft.entities[EntityType.Layout][entityPath] = layout
        draft.entities[EntityType.LayoutComponent][entityPath] = rootComponent
        break
      }
      case 'update:document':
        break
      // default: {
      //   const unknownOperation: never = operation
      // }
    }
  },
)

export function buildSnapshotID(ref: Date | string): string {
  const dateRef = typeof ref === 'string' ? ref : ref.toISOString()
  return `=snapshot/${dateRef}`
}

function createProjectHistory(): ProjectHistory {
  return Object.freeze({
    entries: Object.freeze({}),
    first: null,
    latest: null,
  })
}

export function recordOperation(
  project: Draft<Project>,
  operation: Operation,
): void {
  const operationPath = operation.id.toRelativeId()
  const { first: firstEntry, latest: latestEntry } = project.history
  const entry: HistoryEntry = {
    content: operation,
    next: null,
    prev: latestEntry,
  }
  if (firstEntry === null) {
    project.history.first = operationPath
  }
  if (latestEntry !== null) {
    project.history.entries[latestEntry].next = operationPath
  }
  project.history.entries[operationPath] = entry
  project.history.latest = operationPath
  if (operation.seq && operation.seq > project.latestSeq) {
    project.latestSeq = operation.seq
  }
}

export function createDocumentFromOperation(
  operation: TypedOperation<ProjectOperationPayload, 'create:layout'>,
  content: FileDocument['content'],
): FileDocument {
  return createDocument(
    operation.id.withDomain(EntityType.Document),
    operation.payload.name,
    operation.payload.parentId,
    operation.date,
    operation.payload.documentType,
    operation.holderId,
    content,
  )
}
