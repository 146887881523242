import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { ReactElement, useMemo } from 'react'
import ArrowDownIcon from '../../icons/ArrowDownIcon'
import ArrowUpIcon from '../../icons/ArrowUpIcon'

interface ToolbarSelectorProps<T extends string> {
  disabled?: boolean
  label: string | ReactElement
  onChange: (value: T) => void
  options: Record<T, string> | T[]
  value: T
  widthClassName: string
}
export default function ToolbarSelector<T extends string>({
  disabled,
  label,
  onChange,
  options,
  value,
  widthClassName,
}: ToolbarSelectorProps<T>): ReactElement {
  const optionsRecord = useMemo<Record<T, string>>(() => {
    if (Array.isArray(options)) {
      return Object.fromEntries<string>(
        options.map((option) => [option, option]),
      ) as Record<T, string>
    } else {
      return options
    }
  }, [options])

  return (
    <div className="relative flex-none">
      <Listbox disabled={disabled} onChange={onChange} value={value}>
        {({ open }) => (
          <>
            <div
              className={classNames(
                'h-12 relative',
                open
                  ? 'border-b-2 border-primary'
                  : 'border-b border-secondary border-opacity-30',
              )}
            >
              <Listbox.Label
                className={classNames(
                  'tpg-tool-label absolute left-4 top-1 w-max',
                  open && 'text-primary text-opacity-100',
                  disabled && 'text-opacity-50',
                )}
              >
                {label}
              </Listbox.Label>
              <Listbox.Button className="focusable-wrapper w-full h-full block">
                <div
                  className={classNames(
                    'focusable h-full w-full pl-4 pt-6 pr-10 tpg-menu-1 flex',
                    disabled && 'text-opacity-50',
                    !disabled && 'hover:bg-primary hover:bg-opacity-20',
                    'ring-secondary ring-opacity-80 ring-inset',
                    !disabled &&
                      'active:bg-primary active:bg-opacity-50 active:shadow-none active:ring-0',
                  )}
                  tabIndex={-1}
                >
                  <div className={widthClassName}>{optionsRecord[value]}</div>
                </div>
                <div
                  className={classNames('h-6 w-6 absolute right-2 bottom-1', {
                    'opacity-50': disabled,
                  })}
                >
                  {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </div>
              </Listbox.Button>
            </div>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  'absolute mt-1 bg-surface rounded-lg elevation-dp8',
                  'focus:outline-none',
                )}
              >
                {Object.entries<string>(optionsRecord).map(
                  ([optionValue, optionTag]) => (
                    <Listbox.Option
                      className={({ active }) =>
                        classNames(
                          'tpg-menu-2 px-4 h-8',
                          active && 'bg-primary bg-opacity-20',
                        )
                      }
                      key={optionValue}
                      value={optionValue}
                    >
                      {({ selected }) => (
                        <div
                          className={classNames(
                            'h-full cursor-default flex items-center',
                            selected && 'tpg-menu-1',
                            widthClassName || 'w-max',
                          )}
                        >
                          {optionTag}
                        </div>
                      )}
                    </Listbox.Option>
                  ),
                )}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  )
}
