import { FC, SVGProps } from 'react'

const DeckIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5,7 c2,0 2,0 0,2 l-2,2 c-1,1 -1,1 -2,1 h-11 c-2,0 -2,0 0,-2 l2,-2 c1,-1 1,-1 2,-1 h11z
           m0,3 c1,-1 1.5,-1.5 1.5,-1.75 v1 c0,.25 -.5,.75 -1.5,1.75 l-2,2 c-1,1 -1,1 -2,1 h-11 c-1,0 -1.5,0 -1.5,-.25 v-1 c0,.25 .5,.25 1.5,.25 h11 c1,0 1,0 2,-1 l2,-2z
           m0,2 c1,-1 1.5,-1.5 1.5,-1.75 v1 c0,.25 -.5,.75 -1.5,1.75 l-2,2 c-1,1 -1,1 -2,1 h-11 c-1,0 -1.5,0 -1.5,-.25 v-1 c0,.25 .5,.25 1.5,.25 h11 c1,0 1,0 2,-1 l2,-2z
           m0,2 c1,-1 1.5,-1.5 1.5,-1.75 v1 c0,.25 -.5,.75 -1.5,1.75 l-2,2 c-1,1 -1,1 -2,1 h-11 c-1,0 -1.5,0 -1.5,-.25 v-1 c0,.25 .5,.25 1.5,.25 h11 c1,0 1,0 2,-1 l2,-2z
          "
      />
    </svg>
  )
}

export default DeckIcon
