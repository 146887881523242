import classNames from 'classnames'
import { FC, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import SpinnerIcon from '../../icons/SpinnerIcon'
import { StylableComponentProps } from '../../utils/StylableProps'

interface FileNameDisplayProps extends StylableComponentProps {
  name: string
  size: number | undefined
}

const FileNameDisplay: FC<FileNameDisplayProps> = ({
  className,
  name,
  size,
}) => {
  const indexOfDot = name.indexOf('.', name.length - 9)
  const namePart = indexOfDot >= 0 ? name.slice(0, indexOfDot) : name
  const suffix = indexOfDot >= 0 ? name.slice(indexOfDot) : ''

  return (
    <div className={className}>
      <span className="tpg-prop-label text-center flex justify-center flex-wrap">
        <span className="flex-initial flex justify-center max-w-full">
          <span className="flex-initial block truncate">{namePart}</span>
          <span className="flex-none">{suffix}</span>
        </span>
        <span className="flex-none whitespace-nowrap">
          {typeof size !== 'undefined' ? ` (${readableSize(size)})` : ''}
        </span>
      </span>
    </div>
  )
}

interface UploadResourceProps {
  setIsUploading?: (isLoading: boolean) => void
}

const UploadResource: FC<UploadResourceProps> = () => {
  const [filePreview, setFilePreview] = useState<string | undefined>(undefined)
  const [fileName, setFileName] = useState<string | undefined>(undefined)
  const [fileSize, setFileSize] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onDrop = useCallback((acceptedFiles: File[]): void => {
    const fReader = new FileReader()

    fReader.addEventListener('abort', () =>
      console.info('Resource upload aborted while reading file'),
    )
    fReader.addEventListener('error', () =>
      console.error('Resource upload has failed while reading file'),
    )
    fReader.addEventListener(
      'loadstart',
      (e) => (console.log('LOADSTART=', e), setIsLoading(true)),
    )
    // fReader.addEventListener('progress', function (e) {
    //   console.log('PROGRESS=', e)
    // })
    fReader.addEventListener(
      'loadend',
      (e) => (console.log('LOADEND=', e), setIsLoading(false)),
    )
    fReader.addEventListener('load', () => {
      setFileName(acceptedFiles[0].name)
      setFileSize(acceptedFiles[0].size)
      setFilePreview(fReader.result as string)
    })

    fReader.readAsDataURL(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop,
  })

  return (
    <div {...getRootProps({ className: 'focusable-wrapper w-full h-full' })}>
      <div
        className={classNames(
          'focusable w-full h-full flex items-center justify-center p-4',
          'rounded-lg border-2 border-secondary border-opacity-70 border-dashed',
          'hover:bg-primary hover:bg-opacity-20',
          'ring-primary ring-opacity-80',
          'active:bg-primary active:bg-opacity-50 active:ring-0 active:shadow-none',
        )}
      >
        <input {...getInputProps()} />
        <div className="w-full h-full tpg-menu-2 text-center flex items-center justify-center">
          {isLoading ? (
            <SpinnerIcon className="animate-spin -ml-1 mr-3 w-8 h-8 text-secondary" />
          ) : isDragActive ? (
            <p>
              <FormattedMessage
                defaultMessage="Drop the file here!"
                id="app.upload-resource.message-body-drag-active"
              />
            </p>
          ) : typeof filePreview !== 'undefined' ? (
            <div className="w-full h-full relative">
              <img
                className="max-w-full max-h-full mx-auto border border-secondary"
                src={filePreview}
              />
              {typeof fileName !== 'undefined' && (
                <FileNameDisplay
                  className="absolute -bottom-4 -left-4 -right-4 pb-2 pt-6 px-4 rounded-b-lg bg-gradient-to-t from-surface via-surface to-transparent"
                  name={fileName}
                  size={fileSize}
                />
              )}
            </div>
          ) : (
            <p>
              <FormattedMessage
                defaultMessage="Drag and drop a file here, or click to select a file from your device"
                id="app.upload-resource.message-body"
              />
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadResource

function readableSize(bytes: number): string {
  let size = bytes
  const unitList = ['Bytes', 'KiB', 'MiB', 'GiB']
  for (const unit of unitList) {
    if (size / 1024 < 1) {
      return `${size.toFixed(3)} ${unit}`
    } else {
      size /= 1024
    }
  }
  return `${size.toFixed(3)} TiB`
}
