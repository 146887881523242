import classNames from 'classnames'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FileDocument, Folder } from '../../../core/services/FileElement'
import { buildResourceLink } from '../components/buildResourceLink'

interface DocumentGuiCardProps {
  document: FileDocument | Folder
  selected?: boolean
}

const DocumentGuiCard: FC<DocumentGuiCardProps> = ({ document, selected }) => {
  const { icon: Icon, link, name } = buildResourceLink(document)
  return (
    <li className="block rounded-lg w-48 h-24  bg-surface">
      <Link
        className={classNames(
          'block rounded-lg overflow-hidden h-full w-full elevation-dp1',
          'ring-primary ring-opacity-80 focus:ring-offset-0',
          'active:ring-0 active:shadow-none',
        )}
        to={link}
      >
        <div className={classNames('w-full h-full relative')}>
          <div
            className={classNames(
              'absolute w-full h-full',
              !selected && 'hover:bg-primary hover:bg-opacity-20',
              !selected && 'active:bg-primary active:bg-opacity-50',
            )}
          />
          <div className="w-full h-16 bg-gray-400"></div>
          <div
            className={classNames(
              'w-full h-8 px-1 flex flex-row items-center tpg-list-2',
              selected ? 'bg-secondary text-primary' : 'bg-surface',
            )}
          >
            <Icon
              className={classNames(
                'w-6 h-6 m-1 text-opacity-70',
                selected ? 'text-primary' : 'text-secondary',
              )}
            />
            <div className="truncate">{name}</div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default DocumentGuiCard
