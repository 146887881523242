import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'

export const AuthProvider: React.FC = ({ children }) => {
  return (
    <Auth0Provider
      domain="cardigraph.eu.auth0.com"
      clientId="FA7Tg1UEjgy7vJKWqDDaODrYWwGbfnMP"
      redirectUri={window.location.origin}
      responseType="token id_token"
      audience="https://cardigraph.com/api/"
    >
      {children}
    </Auth0Provider>
  )
}
