import { FC, SVGProps } from 'react'

const WarningIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM13.5 12.5a1.5 1.5 0 0 1 -3 0v-3a1.5 1.5 0 0 1 3 0zm0 5a1.5 1.5 0 0 1 -3 0a1.5 1.5 0 0 1 3 0z" />
    </svg>
  )
}

export default WarningIcon
