// import { Draft, produce } from 'immer'
// import { buildActionCreator } from '../entities/Action'
// import { User, UserId } from './User'

export enum PermissionType {
  Read = 'read',
  Comment = 'comment',
  Edit = 'edit',
  Ownership = 'ownership',
  // | 'export' | 'manage' | 'ownership'
}

export function isPermissionType(str: string): str is PermissionType {
  return Object.values(PermissionType).includes(str as PermissionType)
}

// type UserPermissions = {
//   readonly [k in PermissionTypes]: boolean
// }

// type ACL = Readonly<Record<UserId, UserPermissions>>

// export interface AccessPolicy {
//   readonly acl: ACL
//   readonly owner: User
// }

// export const createAccessPolicy = buildActionCreator(
//   'CREATE_ACCESS_POLICY',
//   (owner: User, acl?: ACL) => ({ acl, owner } as const),
// )

// export type AccessPolicyAction = ReturnType<typeof createAccessPolicy>

// type AccessPolicyReducer =
//   | ((
//       state: undefined,
//       action: ReturnType<typeof createAccessPolicy>,
//     ) => AccessPolicy)
//   | ((state: AccessPolicy, action: AccessPolicyAction) => AccessPolicy)

// export const accessPolicyReducer: AccessPolicyReducer = produce(
//   (
//     draft: Draft<AccessPolicy> | undefined,
//     action: AccessPolicyAction,
//   ): AccessPolicy => {
//     console.log(draft)
//     switch (action.type) {
//       case createAccessPolicy.type:
//         return {
//           acl: action.payload.acl ?? {},
//           owner: action.payload.owner,
//         }
//     }
//   },
// )

// export interface Shareable {
//   accessPolicy: AccessPolicy
// }
