import { FC } from 'react'

const UnimplementedResourceView: FC = () => {
  return (
    <div className="my-16 text-center">
      I dont have a view yet,
      <br />
      Please come back later 🤗
    </div>
  )
}

export default UnimplementedResourceView
