import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import SignupButton from '../infrastructure/auth/SignupButton'

const FrontPage: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <div className="text-center">
      <img
        className="w-32 sm:w-48 h-auto my-8 mx-auto"
        src="/static/logo.svg"
      />
      <h1 className="font-display font-medium text-secondary text-4xl sm:text-7xl">
        <FormattedMessage
          defaultMessage="Cardigraph"
          description="App title"
          id="app-title"
        />
      </h1>
      <div className="my-8">
        <FormattedMessage defaultMessage="An application to help you design and prototype any kind of game cards in a collaborative environment." />
      </div>
      <div className="mx-auto my-8">
        <label className="inline-block bg-primary rounded-lg ">
          {isAuthenticated ? (
            <Link className="btn-primary" to="/app">
              <FormattedMessage
                defaultMessage="Go to the App"
                id="navbar.app"
              />
            </Link>
          ) : (
            <SignupButton className="btn-primary" />
          )}
        </label>
      </div>
    </div>
  )
}

export default FrontPage
