import { useAuth0 } from '@auth0/auth0-react'
import classNames from 'classnames'
import { FC, ReactEventHandler, useCallback } from 'react'
import PersonIcon from '../../icons/PersonIcon'

interface UserAvatarProps {
  size?: 's' | 'm'
}

const UserAvatar: FC<UserAvatarProps> = ({ size = 's' }) => {
  const { getAccessTokenSilently, user } = useAuth0()

  const onImageLoadError: ReactEventHandler<HTMLImageElement> = useCallback(
    () => void getAccessTokenSilently(),
    [getAccessTokenSilently],
  )

  return (
    <div
      className={classNames(
        'ring-1 ring-secondary ring-opacity-80 rounded-full overflow-hidden',
        {
          'h-12 w-12': size === 'm',
          'h-8 w-8': size === 's',
        },
      )}
    >
      {user?.picture ? (
        <img onError={onImageLoadError} src={user.picture} />
      ) : (
        <PersonIcon
          className={classNames('text-secondary', {
            'h-10 w-10 -ml-1': size === 's',
            'h-16 w-16 -ml-2 -mt-0.5': size === 'm',
          })}
        />
      )}
    </div>
  )
}

export default UserAvatar
