import { compile } from 'path-to-regexp'
import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

export enum ResourceEditorType {
  Layout = 'layout',
  Project = 'project',
}

export type RootRoutePath = '/app'
export function buildRootRoutePath(): RootRoutePath {
  return '/app'
}

export type EditorRoutePath = `${RootRoutePath}/:editor(${string})`
type EditorRoutePathParams = {
  editor: ResourceEditorType | ResourceEditorType[] | undefined
}
export function buildEditorRoutePath(
  params?: Partial<EditorRoutePathParams>,
): EditorRoutePath {
  const editorList: string[] = !params?.editor
    ? Object.values(ResourceEditorType)
    : !Array.isArray(params.editor)
    ? [params.editor]
    : params.editor

  return `${buildRootRoutePath()}/:editor(${editorList.join('|')})`
}

export type ProjectRoutePath = `${EditorRoutePath}/${':projectId' | string}`
type ProjectRoutePathParams = EditorRoutePathParams & {
  projectId: string | undefined
}
export function buildProjectRoutePath(
  params?: Partial<ProjectRoutePathParams>,
): ProjectRoutePath {
  const defaultProjectParam = ':projectId'
  return `${buildEditorRoutePath(params)}/${
    params?.projectId ?? defaultProjectParam
  }`
}

export type ResourceRoutePath = `${ProjectRoutePath}/document/${
  | ':resourceId'
  | string}`
type ResourceRoutePathParams = ProjectRoutePathParams & {
  resourceId: string | undefined
}
export function buildResourceRoutePath(
  params?: Partial<ResourceRoutePathParams>,
): ResourceRoutePath {
  const defaultResourceParam = ':resourceId'
  return `${buildProjectRoutePath(params)}/document/${
    params?.resourceId ?? defaultResourceParam
  }`
}

type AppRoutePathParams =
  | ResourceRoutePathParams
  | ProjectRoutePathParams
  | EditorRoutePathParams
type AppRoutePath =
  | ResourceRoutePath
  | ProjectRoutePath
  | EditorRoutePath
  | RootRoutePath
export function buildAppRoutePath(): RootRoutePath
export function buildAppRoutePath(
  params?: EditorRoutePathParams,
): EditorRoutePath
export function buildAppRoutePath(
  params?: ProjectRoutePathParams,
): ProjectRoutePath
export function buildAppRoutePath(
  params?: ResourceRoutePathParams,
): ResourceRoutePath
export function buildAppRoutePath(params?: AppRoutePathParams): AppRoutePath {
  if (!params) {
    return buildRootRoutePath()
  }

  if ('resourceId' in params) {
    return buildResourceRoutePath(params)
  } else if ('projectId' in params) {
    return buildProjectRoutePath(params)
  } else {
    return buildEditorRoutePath(params)
  }
}

export function buildAppRoutePathSet(
  editor?: ResourceEditorType | ResourceEditorType[],
): [ResourceRoutePath, ProjectRoutePath, EditorRoutePath] {
  const params: ResourceRoutePathParams = {
    editor,
    projectId: undefined,
    resourceId: undefined,
  }
  return [
    buildResourceRoutePath(params),
    buildProjectRoutePath(params),
    buildEditorRoutePath(params),
  ]
}

type AppRouteLinkParams =
  | {
      editor: ResourceEditorType
      projectId: string
      resourceId: string
    }
  | {
      editor: ResourceEditorType
      projectId: string
    }
  | {
      editor: ResourceEditorType
    }

const editorRouteLinkBuilder = compile(buildEditorRoutePath())
const projectRouteLinkBuilder = compile(buildProjectRoutePath())
const resourceRouteLinkBuilder = compile(buildResourceRoutePath())

export function buildAppRouteLink(params?: AppRouteLinkParams): string {
  if (!params) {
    return buildRootRoutePath()
  }

  if ('resourceId' in params) {
    return resourceRouteLinkBuilder({
      editor: params.editor,
      projectId: params.projectId,
      resourceId: params.resourceId,
    })
  } else if ('projectId' in params) {
    return projectRouteLinkBuilder({
      editor: params.editor,
      projectId: params.projectId,
    })
  } else {
    return editorRouteLinkBuilder({
      editor: params.editor,
    })
  }
}

interface ResourceRouteMatch {
  editor: ResourceEditorType
  projectId: string
  resourceId: string
}

interface ProjectRouteMatch {
  editor: ResourceEditorType
  projectId: string
  resourceId?: undefined
}

interface EditorRouteMatch {
  editor: ResourceEditorType
  projectId?: undefined
  resourceId?: undefined
}

type AppRouteMatch = EditorRouteMatch | ProjectRouteMatch | ResourceRouteMatch

export function useAppRouteMatch(): AppRouteMatch | undefined {
  const routes = useMemo(() => buildAppRoutePathSet(), [])
  const match = useRouteMatch<AppRouteMatch>(routes)
  return match?.params
}
