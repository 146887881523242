import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import WarningIcon from '../../icons/WarningIcon'

const ResourceNotFound: FC = () => {
  const location = useLocation()

  return (
    <div className="">
      <WarningIcon className="text-yellow-500 w-24 h-24 mx-auto mt-12" />
      <h1 className="tpg-title-6 uppercase text-center my-4">
        <FormattedMessage
          defaultMessage="Nothing to see here"
          id="app.resource-not-found.title"
        />
      </h1>
      <div className="tpg-menu-2 text-center">
        <FormattedMessage
          defaultMessage="We're sorry, but we can't find the resource you were trying to access"
          id="app.resource-not-found.message"
        />
        <br />
        <span className="whitespace-pre-line">
          (<span className="tpg-list-2 font-mono">{location.pathname}</span>)
        </span>
      </div>
      <div className="mt-16 tpg-menu-1 text-center">
        <label className="inline-block bg-primary rounded-lg ">
          <Link className="btn-primary" to="/app">
            <FormattedMessage
              defaultMessage="Go back to the main page"
              id="app.resource-not-found.link-to-main"
            />
          </Link>
        </label>
      </div>
    </div>
  )
}

export default ResourceNotFound
