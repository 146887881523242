type JsonReviver = (key: string, value: unknown) => unknown

export function composeJsonReviver(revivers: JsonReviver[]): JsonReviver {
  return function (key: string, value: unknown): unknown {
    for (const reviver of revivers) {
      const result = reviver(key, value)
      if (typeof result !== 'undefined') {
        return result
      }
    }
    return value
  }
}
