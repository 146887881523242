import { useAuth0 } from '@auth0/auth0-react'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { StylableComponentProps } from '../../utils/StylableProps'

const SignupButton: React.FC<StylableComponentProps> = ({ className }) => {
  const { loginWithRedirect } = useAuth0()
  const signupFn = useCallback(() => {
    return loginWithRedirect({
      redirectUri: `${window.location.origin}/app`,
      screen_hint: 'signup',
    })
  }, [loginWithRedirect])

  return (
    <button className={className} type="button" onClick={signupFn}>
      <FormattedMessage
        defaultMessage="Sign Up"
        description="Signup menu link"
        id="auth.signup"
      />
    </button>
  )
}

export default SignupButton
