import { EntityID } from '../entities/Identificable'
import { Operation, isOperation } from '../entities/Operation'
import { AppOperation } from '../state/AppOperation'
import { composeJsonReviver } from '../utils/composeJsonReviver'
import { isObject } from '../utils/isObject'

export type RemoteResponse = {
  amount: number
  error?: string
  errors?: Record<string, string>
  operations: AppOperation[]
}

export function parseRemoteResponse(str: string): RemoteResponse {
  const response = JSON.parse(
    str,
    composeJsonReviver([EntityID.jsonReviver, reviveRemoteResponseRoot]),
  ) as RemoteResponse
  return response
}

function reviveRemoteResponseRoot(key: string, value: unknown) {
  if (isObject(value)) {
    if (key === '') {
      const operations = Array.isArray(value.operations)
        ? value.operations
        : [value.operations]
      return {
        ...value,
        operations: operations.map(
          (op: Operation) => (isOperation(op, true), Object.freeze(op)),
        ),
      }
    }
  }
  return undefined
}
