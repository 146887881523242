import { Asset } from './Asset'
// import { CardDataTuple } from './CardDataTuple'
import { EntityID, EntityType, Identificable } from './Identificable'

type Order = number

type HolderLayoutRef = EntityID<EntityType.Layout>

interface ParentComponentRef {
  component: GroupComponent
  order: Order
}

type VerticalAlignment = 'top' | 'middle' | 'bottom'
type HorizontalAlignment = 'left' | 'center' | 'right'
type Alignment = `${VerticalAlignment}-${HorizontalAlignment}`

type TextAlignment = HorizontalAlignment | 'justify'

type BlendMode =
  | 'normal'
  | 'multiply'
  | 'screen'
  | 'overlay'
  | 'darken'
  | 'lighten'
  | 'color-dodge'
  | 'color-burn'
  | 'hard-light'
  | 'soft-light'
  | 'difference'
  | 'exclusion'
  | 'hue'
  | 'saturation'
  | 'color'
  | 'luminosity'
// | 'pass-through'

type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900

interface BoxAttributes {
  anchor: Alignment
  angle: number
  border: number
  height: number
  positionX: number
  positionY: number
  width: number
}

interface PaintAttributes {
  fill: string
  fillOpacity: number
  stroke: string
  strokeOpacity: number
}

interface LayerAttributes {
  layerBlendMode: BlendMode
  layerOpacity: number
}

interface TextAttributes {
  fontFamily: string
  fontSize: number
  fontWeight: FontWeight
  textAlignment: TextAlignment
}

interface AssetAttributes {
  assetRef: Asset
}

type Point = [number, number]

interface LineAttributes {
  points: Point[]
}

interface BaseLayoutComponent
  extends Identificable<EntityType.LayoutComponent> {
  name: string
  parent: ParentComponentRef | HolderLayoutRef
}

export type GroupComponent = BaseLayoutComponent &
  BoxAttributes &
  PaintAttributes &
  LayerAttributes

export interface RootComponent extends GroupComponent {
  parent: HolderLayoutRef
}

export type TextComponent = BaseLayoutComponent &
  BoxAttributes &
  PaintAttributes &
  LayerAttributes &
  TextAttributes

export type ImageComponent = BaseLayoutComponent &
  BoxAttributes &
  PaintAttributes &
  LayerAttributes &
  AssetAttributes

export type PolygonComponent = BaseLayoutComponent &
  BoxAttributes &
  PaintAttributes &
  LayerAttributes &
  LineAttributes

export type LayoutComponent =
  | GroupComponent
  | RootComponent
  | TextComponent
  | ImageComponent
  | PolygonComponent

// interface Sourced<T> {
//   apply(data: CardDataTuple): T
//   source: string
// }

// type SourcedComponent<T> = {
//   [Prop in keyof T]: T[Prop] | Sourced<T[Prop]>
// }

export function createRootComponent(
  id: EntityID<EntityType.LayoutComponent>,
  layoutId: EntityID<EntityType.Layout>,
): RootComponent {
  return {
    anchor: 'top-left',
    angle: 0,
    border: 0,
    fill: '',
    fillOpacity: 1,
    height: 0,
    id,
    layerBlendMode: 'normal',
    layerOpacity: 1,
    name: 'Root Component', // TODO: Load from i18n so that it matches user's language
    parent: layoutId,
    positionX: 0,
    positionY: 0,
    stroke: '',
    strokeOpacity: 1,
    width: 0,
  }
}
