import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

const StyledTab: FC = ({ children }) => {
  return (
    <Tab
      className={({ selected }) =>
        classNames(
          'flex-initial w-1/2 h-12 focusable-wrapper block',
          selected && 'border-b-4 border-secondary',
        )
      }
      type="button"
    >
      <div
        className={classNames(
          'focusable h-full w-full tpg-menu-1 text-center',
          'hover:bg-primary hover:bg-opacity-20',
          'ring-primary ring-opacity-80 ring-inset',
          'active:bg-primary active:bg-opacity-50 active:shadow-none active:ring-0',
        )}
        tabIndex={-1}
      >
        <div className="block h-full pt-5">{children}</div>
      </div>
    </Tab>
  )
}

const LayoutEditorComponentTree: FC = () => {
  return (
    <div className="flex-none w-72 bg-blue-100">
      <Tab.Group>
        <Tab.List className="h-12 flex border-b border-secondary border-opacity-10">
          <StyledTab>
            <FormattedMessage
              defaultMessage="Front"
              id="app.layout-editor.component-tree.front"
            />
          </StyledTab>
          <StyledTab>
            <FormattedMessage
              defaultMessage="Back"
              id="app.layout-editor.component-tree.back"
            />
          </StyledTab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>Front Content</Tab.Panel>
          <Tab.Panel>Back Content</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default LayoutEditorComponentTree
