import { Popover } from '@headlessui/react'
import classNames from 'classnames'
import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import LogoutButton from '../../infrastructure/auth/LogoutButton'
import UserAvatar from './UserAvatar'

const UserProfileMenu: React.FC = () => {
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLSpanElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 8 } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  })

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className="focusable-wrapper m-1 rounded-full"
            ref={setReferenceElement}
          >
            <div
              className={classNames(
                'focusable h-12 w-12 p-2 icon-btn-primary',
                open && 'bg-surface bg-opacity-50 hover:bg-opacity-50',
              )}
              tabIndex={-1}
            >
              <UserAvatar />
            </div>
          </Popover.Button>

          <Popover.Panel
            className="popper-container bg-surface rounded-lg py-2 elevation-dp8 z-10"
            focus={true}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div
              className="popper-arrow"
              ref={setArrowElement}
              style={styles.arrow}
            />
            <ul className="flex flex-col w-52">
              <li className="list-element h-8">
                <LogoutButton className="" />
              </li>
            </ul>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default UserProfileMenu
