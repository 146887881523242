import { Draft, produce } from 'immer'
import { EntityID, EntityType, Identificable } from '../entities/Identificable'
import { UserId } from '../entities/SID'
// import { Shareable } from './AccessPolicy'

export enum FileDocumentType {
  Layout = 'Layout',
  DataSource = 'DataSource',
  Folder = 'Folder',
  // | 'Deck' | 'Asset'
}

export function isFileDocumentType(str: string): str is FileDocumentType {
  return Object.values(FileDocumentType).includes(str as FileDocumentType)
}

export type DocumentID = EntityID<EntityType.Document>

export interface IFileElement
  extends /*Shareable,*/ Identificable<EntityType.Document> {
  readonly created: string // timestamp
  readonly createdBy: UserId
  readonly documentType: FileDocumentType
  readonly holderId: EntityID<EntityType.Project>
  readonly lastModified: string // timestamp
  readonly lastModifiedBy: UserId
  readonly name: string
  readonly parentId: DocumentID | null
  // readonly size?: number
}

export interface Folder extends IFileElement {
  readonly documentType: FileDocumentType.Folder
}

export interface FileDocument extends IFileElement {
  readonly content: EntityID<EntityType.Layout | EntityType.DataSource>
  readonly documentType: Exclude<FileDocumentType, FileDocumentType.Folder>
}

export function createDocument(
  id: DocumentID,
  name: string,
  parent: DocumentID | null,
  date: string,
  documentType: FileDocumentType.Folder,
  holderId: EntityID<EntityType.Project>,
): Folder
export function createDocument(
  id: DocumentID,
  name: string,
  parent: DocumentID | null,
  date: string,
  documentType: Exclude<FileDocumentType, FileDocumentType.Folder>,
  holderId: EntityID<EntityType.Project>,
  content?: EntityID<EntityType.Layout | EntityType.DataSource>,
): FileDocument
export function createDocument(
  id: DocumentID,
  name: string,
  parentId: DocumentID | null,
  date: string,
  documentType: FileDocumentType,
  holderId: EntityID<EntityType.Project>,
  content?: EntityID<EntityType.Layout | EntityType.DataSource>,
): FileDocument | Folder
export function createDocument(
  id: DocumentID,
  name: string,
  parentId: DocumentID | null,
  date: string,
  documentType: FileDocumentType,
  holderId: EntityID<EntityType.Project>,
  content?: EntityID<EntityType.Layout | EntityType.DataSource>,
): FileDocument | Folder {
  if (documentType === FileDocumentType.Folder) {
    return Object.freeze({
      // content: undefined,
      created: date,
      createdBy: id.author,
      documentType: FileDocumentType.Folder,
      holderId,
      id,
      lastModified: date,
      lastModifiedBy: id.author,
      name,
      parentId,
    } as const) as Folder
  } else {
    return Object.freeze({
      content,
      created: date,
      createdBy: id.author,
      documentType,
      holderId,
      id,
      lastModified: date,
      lastModifiedBy: id.author,
      name,
      parentId,
    } as const) as FileDocument
  }
}

export type UpdateDocument = {
  (
    state: FileDocument,
    changes: Partial<FileDocument>,
    date: string,
  ): FileDocument
  (state: Folder, changes: Partial<Folder>, date: number): Folder
}

type UpdateDocumentRecipe = {
  (
    state: Draft<FileDocument>,
    changes: Partial<FileDocument>,
    date: string,
  ): FileDocument | void
  (state: Draft<Folder>, changes: Partial<Folder>, date: number): Folder | void
}

export const updateDocument: UpdateDocument = produce(
  function updateDocumentRecipe<F extends Folder | FileDocument>(
    draft: Draft<F>,
    changes: Partial<F>,
    date: string,
  ): F | void {
    const type = draft.documentType
    draft.lastModified = date
    Object.assign(draft, changes)
    // if someone tries to change the type through an update, change it back
    draft.documentType = type
  } as UpdateDocumentRecipe,
)
