import { ComponentType, Fragment, ReactNode, createElement } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  FileDocument,
  FileDocumentType,
  Folder,
} from '../../../core/services/FileElement'
import { isDraftsProject } from '../../../core/state/AppOperation'
import { Project } from '../../../core/state/Project'
import CardLayoutIcon from '../../icons/CardLayoutIcon'
import CardsIcon from '../../icons/CardsIcon'
import FolderIcon from '../../icons/FolderIcon'
import HomeIcon from '../../icons/HomeIcon'
import TableIcon from '../../icons/TableIcon'
import { StylableComponentProps } from '../../utils/StylableProps'
import { ResourceEditorType, buildAppRouteLink } from './routes'

export interface ResourceLinkData {
  icon: ComponentType<StylableComponentProps>
  id: string
  link: string
  name: string | ReactNode
}

const premadeLinks = Object.freeze({
  app: {
    icon: HomeIcon,
    id: 'app',
    link: buildAppRouteLink(),
    name: createElement(FormattedMessage, {
      defaultMessage: 'Home',
      id: 'sidebar.nav.home',
    }),
  },
  drafts: {
    icon: CardsIcon,
    id: 'drafts',
    link: buildAppRouteLink({
      editor: ResourceEditorType.Project,
      projectId: 'drafts',
    }),
    name: createElement(FormattedMessage, {
      defaultMessage: 'Drafts',
      id: 'sidebar.nav.drafts',
    }),
  },
} as const)

export type BuildResourceLinkParam =
  | keyof typeof premadeLinks
  | FileDocument
  | Folder
  | Project

export function buildResourceLink(
  resource: BuildResourceLinkParam,
): ResourceLinkData {
  if (typeof resource === 'string') {
    if (resource in premadeLinks) {
      return premadeLinks[resource]
    } else {
      throw new TypeError(
        `'buildResourceLink()' received invalid param, expected [${Object.keys(
          premadeLinks,
        ).join()}] but received "${resource}"`,
      )
    }
  } else if ('documentType' in resource) {
    const { documentType, id, name } = resource
    const projectId = isDraftsProject(resource.holderId)
      ? 'drafts'
      : resource.holderId.toRelativeId()

    let editor: ResourceEditorType
    let icon
    switch (documentType) {
      case FileDocumentType.Folder:
        editor = ResourceEditorType.Project
        icon = FolderIcon
        break
      case FileDocumentType.Layout:
        editor = ResourceEditorType.Layout
        icon = CardLayoutIcon
        break
      case FileDocumentType.DataSource:
        editor = ResourceEditorType.Project
        icon = TableIcon
        break
      default: {
        const unrecognizedType: never = documentType
        console.error(
          `Received unrecognized 'documentType': "${
            unrecognizedType as string
          }"`,
        )
        editor = ResourceEditorType.Project
        icon = Fragment
      }
    }

    const link = buildAppRouteLink({
      editor,
      projectId,
      resourceId: resource.id.toRelativeId(),
    })
    return { icon, id: id.toString(), link, name }
  } else if (isDraftsProject(resource)) {
    return premadeLinks['drafts']
  } else {
    return {
      icon: CardsIcon,
      id: resource.id.toString(),
      link: buildAppRouteLink({
        editor: ResourceEditorType.Project,
        projectId: resource.id.toRelativeId(),
      }),
      name: resource.name,
    }
  }
}
