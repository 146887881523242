import { useEffect, useState } from 'react'

export type ResponsiveBreakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl'
type BreakpointValuesType = {
  [k in ResponsiveBreakpoint]: number
}

const breakpointValues: BreakpointValuesType = {
  '2xl': 1536,
  'lg': 1024,
  'md': 768,
  'sm': 640,
  'xl': 1280,
} as const

export default function useBreakpointQuery(
  breakpoint: ResponsiveBreakpoint,
): boolean {
  const query = `(min-width: ${breakpointValues[breakpoint]}px)`

  const [queryMatches, setQueryMatches] = useState<boolean>(
    window.matchMedia(query).matches,
  )

  useEffect(() => {
    const mqList = window.matchMedia(query)
    setQueryMatches(mqList.matches)
    const listener = () => setQueryMatches(mqList.matches)
    mqList.addEventListener('change', listener)

    return () => mqList.removeEventListener('change', listener)
  }, [query])

  return queryMatches
}
