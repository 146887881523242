import classNames from 'classnames'
import {
  ChangeEventHandler,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import {
  CardFormat,
  DEFAULT_CARD_SIZES,
  Dimensions,
  MeasurementUnit,
} from '../../../core/entities/Layout'
import AddCommentIcon from '../../icons/AddCommentIcon'
import ToolbarSelector from '../components/ToolbarSelector'

const cardSizesMessages = defineMessages({
  [CardFormat.Custom]: {
    defaultMessage: 'Custom',
    id: 'core.default-card-size.custom',
  },
  [CardFormat.Standard]: {
    defaultMessage: 'Standard',
    id: 'core.default-card-size.standard',
  },
  [CardFormat.Tarot]: {
    defaultMessage: 'Tarot',
    id: 'core.default-card-size.tarot',
  },
  [CardFormat.European]: {
    defaultMessage: 'European',
    id: 'core.default-card-size.european',
  },
  [CardFormat.MiniEuropean]: {
    defaultMessage: 'Mini-European',
    id: 'core.default-card-size.mini-european',
  },
  [CardFormat.American]: {
    defaultMessage: 'American',
    id: 'core.default-card-size.american',
  },
  [CardFormat.MiniAmerican]: {
    defaultMessage: 'Mini-American',
    id: 'core.default-card-size.mini-american',
  },
  [CardFormat.Square]: {
    defaultMessage: 'Square',
    id: 'core.default-card-size.square',
  },
  [CardFormat.MiniSquare]: {
    defaultMessage: 'Mini-Square',
    id: 'core.default-card-size.mini-square',
  },
  height: {
    defaultMessage: 'Height',
    id: 'app.card-size-controls.card-size-height-label',
  },
  units: {
    defaultMessage: 'Units',
    id: 'app.card-size-controls.card-size-units-label',
  },
  width: {
    defaultMessage: 'Width',
    id: 'app.card-size-controls.card-size-width-label',
  },
})

interface CardDimensionInputProps {
  align: 'left' | 'right'
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  prop: keyof Dimensions
  value: number
}
function CardDimensionInput({
  align,
  disabled = false,
  onChange,
  prop,
  value,
}: CardDimensionInputProps) {
  const intl = useIntl()
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const onFocus = useCallback(
    (event) => {
      setIsFocused(true)
      console.log(`onFocus(${prop})=`, event)
    },
    [prop],
  )
  const onBlur = useCallback(
    (event) => {
      setIsFocused(false)
      console.log(`onBlur(${prop})=`, event)
    },
    [prop],
  )
  return (
    <>
      <label
        className={classNames(
          'tpg-tool-label mt-1 mr-0 mb-0 px-1',
          isFocused && 'text-primary text-opacity-100',
          disabled && 'text-opacity-50',
          {
            'ml-auto text-right': align === 'right',
            'mr-auto text-left': align === 'left',
          },
        )}
        htmlFor={`card-size-${prop}-input`}
      >
        {intl.formatMessage(cardSizesMessages[prop])}
      </label>
      <input
        className={classNames(
          'w-full h-full tpg-menu-1 no-spinner',
          'bg-transparent p-0 pt-5 px-1 border-0',
          disabled && 'text-opacity-50',
          !disabled && 'hover:bg-primary hover:bg-opacity-20',
          !disabled &&
            'active:bg-primary active:bg-opacity-50 active:shadow-none active:ring-0',
          'focus:outline-none focus:ring-0',
          'focus:border-primary focus:border-opacity-100 focus:border-b-2 focus:pb-0',
          'pb-px border-b border-secondary border-opacity-30',
          {
            'ml-auto text-right': align === 'right',
            'mr-auto text-left': align === 'left',
          },
        )}
        disabled={disabled}
        id={`card-size-${prop}-input`}
        name={`card-size-${prop}-input`}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        type="number"
        value={Number.isFinite(value) ? value : ''}
      />
    </>
  )
}

interface CardDimensionsProps {
  disabled?: boolean
  onChange?: (value: Dimensions) => void
  value: Dimensions
}
function CardDimensions({ disabled, onChange, value }: CardDimensionsProps) {
  const onChangeWidth = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (typeof onChange !== 'undefined') {
        onChange({
          height: value.height,
          width: Number.parseFloat(event.target.value),
        })
      }
    },
    [onChange, value.height],
  )
  const onChangeHeight = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (typeof onChange !== 'undefined') {
        onChange({
          height: Number.parseFloat(event.target.value),
          width: value.width,
        })
      }
    },
    [onChange, value.width],
  )

  return (
    <div className="card-dimensions-grid w-28 flex-none">
      <CardDimensionInput
        align="right"
        disabled={disabled}
        onChange={onChangeWidth}
        prop="width"
        value={value.width}
      />
      <div className="w-4 tpg-tool-label mt-1 text-center">&times;</div>
      <div
        className={classNames(
          'w-4 h-full tpg-menu-1 pt-6 text-center self-center',
          'border-b border-secondary border-opacity-30',
        )}
      >
        &times;
      </div>
      <CardDimensionInput
        align="left"
        disabled={disabled}
        onChange={onChangeHeight}
        prop="height"
        value={value.height}
      />
    </div>
  )
}

export default function LayoutEditorToolbar(): ReactElement {
  const intl = useIntl()
  const [selectedCardFormat, setSelectedCardFormat] = useState<CardFormat>(
    CardFormat.MiniAmerican,
  )
  const [cardSize, setCardSize] = useState<Dimensions>(
    DEFAULT_CARD_SIZES[selectedCardFormat],
  )
  const [cardSizeUnits, setCardSizeUnits] = useState<MeasurementUnit>(
    MeasurementUnit.mm,
  )

  const onCardFormatSelection = useCallback((cardFormat: CardFormat) => {
    setSelectedCardFormat(cardFormat)
    if (cardFormat !== CardFormat.Custom) {
      setCardSize(DEFAULT_CARD_SIZES[cardFormat])
    }
  }, [])

  const cardFormatOptions = useMemo<Record<CardFormat, string>>(() => {
    return Object.fromEntries(
      Object.values(CardFormat).map((cardSizeName) => [
        cardSizeName,
        intl.formatMessage(cardSizesMessages[cardSizeName]),
      ]),
    ) as Record<CardFormat, string>
  }, [intl])

  return (
    <div
      className={classNames(
        'flex-none flex flex-row items-center h-12',
        'border-secondary border-opacity-10 border-b',
      )}
    >
      <div className="h-12 w-12 flex-none">
        <AddCommentIcon className="h-6 w-6 m-1 text-secondary text-opacity-50" />
      </div>
      <div className="flex-none ml-auto flex">
        <ToolbarSelector
          label={
            <FormattedMessage
              defaultMessage="Card Format"
              id="app.card-size-controls.card-format-label"
            />
          }
          onChange={onCardFormatSelection}
          options={cardFormatOptions}
          value={selectedCardFormat}
          widthClassName="w-36"
        />
        <CardDimensions
          disabled={selectedCardFormat !== CardFormat.Custom}
          onChange={setCardSize}
          value={cardSize}
        />
        <ToolbarSelector
          disabled={true}
          label={
            <FormattedMessage
              defaultMessage="Units"
              id="app.card-size-controls.card-size-units-label"
            />
          }
          onChange={setCardSizeUnits}
          options={Object.values(MeasurementUnit)}
          value={cardSizeUnits}
          widthClassName="w-8"
        />
      </div>
    </div>
  )
}
