import { createSelector } from 'reselect'
import { SiteId, UserId } from '../entities/SID'
import { DocumentID, Folder } from '../services/FileElement'
import { Actor } from '../services/User'
import { AppState, getDraftsProjectId, getMetaProjectId } from './AppOperation'
import { Project } from './Project'

export function selectProjects(state: AppState): Record<string, Project> {
  return state.projects
}

export function selectCurrentActor(state: AppState): Actor {
  return state.currentActor
}

export function selectCurrentActorId(state: AppState): UserId {
  return state.currentActor.id
}

export function selectCurrentSiteId(state: AppState): SiteId {
  return state.currentActor.currentSite
}

export const selectDraftsProject = createSelector(
  [selectProjects, selectCurrentActorId],
  (projects, currentActorId) =>
    projects[getDraftsProjectId(currentActorId).toRelativeId()],
)

export const selectMetaProject = createSelector(
  [selectProjects, selectCurrentActorId],
  (projects, currentActorId) =>
    projects[getMetaProjectId(currentActorId).toRelativeId()],
)

export const selectPersonalProjectsWithoutDrafts = createSelector(
  [selectProjects, selectDraftsProject, selectMetaProject],
  (projects, drafts, meta) =>
    Object.values(projects).filter(
      ({ id }) => id !== drafts.id && id !== meta.id,
    ),
)

export const selectPersonalProjects = createSelector(
  [selectDraftsProject, selectPersonalProjectsWithoutDrafts],
  (drafts, projects) => [
    drafts,
    ...projects.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
  ],
)

export function findDocumentRoot(project: Project, fileId: DocumentID): Folder {
  let file = project.documents[fileId.toRelativeId()]
  while (file.parentId !== null) {
    const parentId = file.parentId.toRelativeId()
    file = project.documents[parentId]
  }
  return file as Folder
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeSelectProject = (relativeProjectId: string | undefined) =>
  createSelector([selectProjects, selectDraftsProject], (projects, drafts):
    | Project
    | undefined =>
    typeof relativeProjectId === 'undefined'
      ? undefined
      : relativeProjectId === 'drafts'
      ? drafts
      : projects[relativeProjectId],
  )

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeSelectResource = (
  relativeDocumentId: string | undefined,
  relativeProjectId: string | undefined,
) =>
  createSelector([makeSelectProject(relativeProjectId)], (project) => {
    if (typeof project === 'undefined') {
      return undefined
    }
    const resource =
      typeof relativeDocumentId !== 'undefined'
        ? project.documents[relativeDocumentId] ?? null
        : null

    return { project, resource }
  })

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeSelectProjectDocuments = (projectId: string) =>
  createSelector([selectProjects, selectDraftsProject], (projects, drafts) => {
    if (projectId === 'drafts') {
      return Object.values(drafts.documents)
    } else {
      return Object.values(projects[projectId]?.documents ?? {})
    }
  })

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeSelectFolderChildren = (folder: Folder) => {
  return createSelector([selectProjects], (projects) => {
    const holder = projects[folder.holderId.toRelativeId()]
    if (!holder) {
      return []
    }
    const documents = Object.values(holder?.documents)
    const folderId = folder.id.toString()
    return documents.filter(
      (document) =>
        document.parentId && document.parentId.toString() === folderId,
    )
  })
}
