import { produce } from 'immer'
import { EntityID, EntityType } from '../entities/Identificable'
import { Operation, TypedOperation } from '../entities/Operation'
import { UserId, getSiteIdFromSiteNumber } from '../entities/SID'
import { VectorClock } from '../entities/VectorClock'
import { PermissionType } from '../services/AccessPolicy'
import { Actor, createOfflineActor } from '../services/User'
import {
  Project,
  ProjectID,
  ProjectOperationPayload,
  createProject,
} from './Project'

type ProjectsRecord = Readonly<Record<string, Project>>

export interface AppState {
  readonly currentActor: Actor
  readonly outbox: Operation[]
  readonly projects: ProjectsRecord
}

export type ProjectState = {
  clock: VectorClock
  id: ProjectID
  seq: number
  snapshot: string
}

export type AppOperationPayload = ProjectOperationPayload & {
  'boop:project': null | {
    readonly data: { readonly [key: string]: ProjectState | null }
  }
  'boop:user': null
  'create:project': { readonly name: string }
  'resync:project': { readonly data: Project }
  'resync:user': {
    readonly projects: { readonly [key: string]: ProjectState | null }
    readonly user: Actor
  }
  // 'update:project': undefined
}

export type AppOperation<
  T extends keyof AppOperationPayload = keyof AppOperationPayload
> = TypedOperation<AppOperationPayload, T>

export type AppOperationType = keyof AppOperationPayload

export function getDraftsProjectId(
  actorId: UserId,
): EntityID<EntityType.Project> {
  return new EntityID(
    1,
    EntityType.Project,
    getSiteIdFromSiteNumber(0),
    actorId,
  )
}

export function getMetaProjectId(
  actorId: UserId,
): EntityID<EntityType.Project> {
  return new EntityID(
    0,
    EntityType.Project,
    getSiteIdFromSiteNumber(0),
    actorId,
  )
}

function buildDraftsProject(actorId: UserId, date: string): Project {
  const draftProjectId = getDraftsProjectId(actorId)
  return createProject(draftProjectId, 'drafts', date)
}

function buildMetaProject(actorId: UserId, date: string): Project {
  const metaProjectId = getMetaProjectId(actorId)
  return createProject(metaProjectId, '__meta__', date)
}

export function isDraftsProject(arg: Project | ProjectID): boolean {
  const id: ProjectID = 'id' in arg ? arg.id : arg
  return (
    id.isOfDomain(EntityType.Project) &&
    id.ns === 1 &&
    id.site === getSiteIdFromSiteNumber(0)
  )
}

// export function isMetaProject(arg: Project | ProjectID): boolean {
//   const id: ProjectID = 'id' in arg ? arg.id : arg
//   return id.isOfDomain(EntityType.Project) && id.ns === 0 && id.siteNumber === 0
// }

export function buildInitialAppState(actorId: UserId, date = ''): AppState {
  const draftsProject = buildDraftsProject(actorId, date)
  const metaProject = produce(buildMetaProject(actorId, date), (draft) => {
    draft.clock[draft.id.sid] = 0
  })
  const currentActor = createOfflineActor(actorId, {
    [metaProject.id.toString()]: PermissionType.Ownership,
    [draftsProject.id.toString()]: PermissionType.Ownership,
  })

  return Object.freeze({
    currentActor,
    outbox: [],
    projects: {
      [metaProject.id.toRelativeId()]: metaProject,
      [draftsProject.id.toRelativeId()]: draftsProject,
    },
  })
}

export function getProjectState(
  project: Pick<Project, 'clock' | 'id' | 'latestSeq' | 'latestSnapshotId'>,
): ProjectState
export function getProjectState(project: null | undefined): null
export function getProjectState(
  project:
    | Pick<Project, 'clock' | 'id' | 'latestSeq' | 'latestSnapshotId'>
    | null
    | undefined,
): ProjectState | null
export function getProjectState(
  project:
    | Pick<Project, 'clock' | 'id' | 'latestSeq' | 'latestSnapshotId'>
    | null
    | undefined,
): ProjectState | null {
  if (typeof project === 'undefined' || project === null) {
    return null
  }
  return Object.freeze({
    clock: project.clock,
    id: project.id,
    seq: project.latestSeq,
    snapshot: project.latestSnapshotId,
  })
}
