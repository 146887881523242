import { useAuth0 } from '@auth0/auth0-react'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { StylableComponentProps } from '../../utils/StylableProps'

const LoginButton: React.FC<StylableComponentProps> = ({ className }) => {
  const { loginWithRedirect } = useAuth0()
  const loginFn = useCallback(() => {
    return loginWithRedirect({ redirectUri: `${window.location.origin}/app` })
  }, [loginWithRedirect])

  return (
    <button className={className} type="button" onClick={loginFn}>
      <FormattedMessage
        defaultMessage="Login"
        description="Login menu link"
        id="auth.login"
      />
    </button>
  )
}

export default LoginButton
