import { ThunkAction } from 'redux-thunk'
import { EntityID, EntityType } from '../entities/Identificable'
import { Actor } from '../services/User'
import {
  AppOperation,
  AppOperationPayload,
  AppOperationType,
  AppState,
} from './AppOperation'
import { timestampOperation } from './ClockManager'
import { OperationBroadcaster } from './OperationBroadcaster'
import { Project } from './Project'

export type PromisedAppThunk = ThunkAction<
  Promise<AppOperation>,
  AppState,
  unknown,
  AppOperation
>
// type AppThunk = ThunkAction<void, AppState, unknown, AppOperation>

export type OperationHandler = {
  readonly createLocalOperation: <T extends AppOperationType>(
    type: T,
    holderId: EntityID<EntityType.Project>,
    payload: AppOperationPayload[T],
  ) => PromisedAppThunk

  // readonly createRemoteOperation: (operation: Operation) => AppThunk
}

export function OperationHandler(
  broadcast: OperationBroadcaster,
): OperationHandler {
  return Object.freeze({
    createLocalOperation,
    // createRemoteOperation,
  })

  function createLocalOperation<T extends AppOperationType>(
    type: T,
    holderId: EntityID<EntityType.Project>,
    payload: AppOperationPayload[T],
  ): PromisedAppThunk {
    return async function (dispatch, getState): Promise<AppOperation> {
      const globalState = getState()
      // Authorizer!?

      const holder = globalState.projects[holderId.toRelativeId()]
      const op = buildOperation(type, globalState.currentActor, holder, payload)

      const serverResponse = broadcast(op)
      dispatch(op) // Update clock, push history-entry, update project state, REMOVE FROM QUEUE.ROOT IF NEEDED?
      await serverResponse
      return op
    }
  }

  // function createRemoteOperation(operation: Operation): AppThunk {
  //   return function (dispatch): void {
  //     dispatch(operation)
  //   }
  // }
}

export function buildOperation<T extends AppOperationType>(
  type: T,
  author: Pick<Actor, 'currentSite' | 'id'>,
  holder: Pick<Project, 'clock' | 'id' | 'latestSeq' | 'latestSnapshotId'>,
  payload: AppOperationPayload[T],
  seq: number | null = null,
): AppOperation {
  const { id: originUser, currentSite: originSite } = author
  const {
    clock,
    id: holderId,
    latestSeq,
    latestSnapshotId: snapshotId,
  } = holder
  const op = timestampOperation(clock, originUser, originSite, {
    holderId,
    latestSeq,
    payload,
    seq,
    snapshotId,
    type,
  })
  return op
}
