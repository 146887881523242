import { FC, SVGProps } from 'react'

const PeopleIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15 8C15 6.58 14.5 5.27 13.67 4.24C14.09 4.1 14.53 4 15 4C17.21 4 19 5.79 19 8C19 10.21 17.21 12 15 12C14.57 12 14.16 11.91 13.77 11.79C13.74 11.78 13.71 11.77 13.67 11.76C14.5 10.73 15 9.42 15 8ZM16.66 13.13C18.03 14.06 19 15.32 19 17V20H23V17C23 14.82 19.42 13.53 16.66 13.13ZM9 4C11.21 4 13 5.79 13 8C13 10.21 11.21 12 9 12C6.79 12 5 10.21 5 8C5 5.79 6.79 4 9 4ZM9 13C11.67 13 17 14.34 17 17V20H1V17C1 14.34 6.33 13 9 13Z" />
    </svg>
  )
}

export default PeopleIcon
