import classNames from 'classnames'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { selectPersonalProjects } from '../../../core/state/appSelectors'
import CardsIcon from '../../icons/CardsIcon'
import HomeIcon from '../../icons/HomeIcon'
import PeopleIcon from '../../icons/PeopleIcon'
import SettingsIcon from '../../icons/SettingsIcon'
import { ResourceEditorType, buildAppRouteLink } from '../components/routes'
import { useAppSelector } from '../store/OperationHandlerProvider'
import NewDocumentFab from './NewDocumentFab'
import SidebarMenuItem from './SidebarMenuItem'
import { useSidebarState } from './SidebarStateProvider'
import UserAvatar from './UserAvatar'
import UserPlaque from './UserPlaque'

const SidebarMenu: FC = () => {
  const { isFullWidth, isOpen } = useSidebarState()

  const { url } = useRouteMatch()
  const [drafts, ...projects] = useAppSelector(selectPersonalProjects)
  // console.log('PROJECTS=', drafts, projects)

  // const asideElementRef = useRef<HTMLElement | null>(null)
  // useEffect(() => {
  //   const listener = (event: MouseEvent) => {
  //     const target = event.target as HTMLElement

  //     if (!isOpen) return
  //     if (asideElementRef.current?.contains(target)) return

  //     toggle()
  //   }
  //   window.addEventListener('mousedown', listener)
  // }, [isOpen, toggle])

  return (
    <>
      {isFullWidth && (
        <div
          className={classNames(
            'bg-black z-10',
            isOpen ? 'absolute opacity-60 inset-0' : 'opacity-0',
          )}
        ></div>
      )}
      <aside
        className={classNames(
          'flex-none w-64 border-r border-secondary border-opacity-10',
          isFullWidth ? 'bg-surface z-20' : '',
          isOpen ? 'block' : 'hidden',
          'lg:block',
        )}
      >
        <UserPlaque />
        <div className="py-4 pl-6">
          <NewDocumentFab />
        </div>
        <ul>
          <SidebarMenuItem
            disabled={true}
            icon={<HomeIcon className="m-1" />}
            label={
              <FormattedMessage defaultMessage="Home" id="sidebar.nav.home" />
            }
            link={`${url}`}
            strictMatch={true}
          />
          <SidebarMenuItem
            disabled={!drafts}
            icon={<CardsIcon className="m-1" />}
            label={
              <FormattedMessage
                defaultMessage="Drafts"
                id="sidebar.nav.drafts"
              />
            }
            link={buildAppRouteLink({
              editor: ResourceEditorType.Project,
              projectId: 'drafts',
            })}
          />
          {projects.map((project) => (
            <SidebarMenuItem
              disabled={false}
              icon={<CardsIcon className="m-1" />}
              key={project.id.toString()}
              label={project.name}
              link={buildAppRouteLink({
                editor: ResourceEditorType.Project,
                projectId: project.id.toRelativeId(),
              })}
            />
          ))}
        </ul>
        <div className="border-t border-secondary border-opacity-10 my-2">
          <div className="h-8 px-6 pt-3 tpg-colrow text-opacity-70 uppercase">
            <FormattedMessage defaultMessage="Teams" id="sidebar.nav.teams" />
          </div>
          <ul>
            <SidebarMenuItem
              icon={<UserAvatar />}
              label={
                <FormattedMessage
                  defaultMessage="Personal Team"
                  id="sidebar.nav.teams.personal-team"
                />
              }
              link={`${url}/team/personal`}
              strictMatch={true}
            >
              <ul>
                <SidebarMenuItem
                  disabled={true}
                  icon={<CardsIcon />}
                  label={
                    <FormattedMessage
                      defaultMessage="Projects"
                      id="sidebar.nav.team.projects"
                    />
                  }
                  link={`${url}/team/personal/projects`}
                  nested
                />
                <SidebarMenuItem
                  disabled
                  icon={<PeopleIcon />}
                  label={
                    <FormattedMessage
                      defaultMessage="Members"
                      id="sidebar.nav.team.members"
                    />
                  }
                  link={`${url}/team/personal/members`}
                  nested
                />
                <SidebarMenuItem
                  disabled
                  icon={<SettingsIcon />}
                  label={
                    <FormattedMessage
                      defaultMessage="Settings"
                      id="sidebar.nav.team.settings"
                    />
                  }
                  link={`${url}/team/personal/settings`}
                  nested
                />
              </ul>
            </SidebarMenuItem>
          </ul>
        </div>
      </aside>
    </>
  )
}

export default SidebarMenu
