import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import LoginButton from '../infrastructure/auth/LoginButton'

const NavBar: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <header className="flex justify-end items-center h-16">
      <nav className="flex-none">
        <ul className="flex space-x-4">
          <li></li>
          <li>
            {isAuthenticated ? (
              <Link className="btn-secondary" to="/app">
                <FormattedMessage
                  defaultMessage="Go to the App"
                  id="navbar.app"
                />
              </Link>
            ) : (
              <LoginButton className="btn-secondary" />
            )}
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default NavBar
