import { SiteId, UserId, getSiteIdFromSiteNumber } from '../entities/SID'
import { PermissionType } from './AccessPolicy'

export enum UserSubscription {
  Offline = 'OFFLINE',
  Starter = 'STARTER',
}
type TeamSubscription = string

export interface User {
  readonly id: UserId
}

export interface ActorSite {
  readonly id: string
  readonly lastConnection: string | null
  readonly lastIp: string | null
}

type PermissionsRecord = Readonly<Record<string, PermissionType>>

export interface Actor extends User {
  readonly currentSite: SiteId
  readonly permissions: PermissionsRecord
  readonly sites: Readonly<Record<SiteId, ActorSite>>
  readonly subscription: UserSubscription
}

export interface Team extends User {
  readonly subscription: TeamSubscription
}

export function createOfflineActor(
  actorId: UserId,
  permissions?: PermissionsRecord,
): Actor {
  return Object.freeze({
    currentSite: getSiteIdFromSiteNumber(0),
    id: actorId,
    permissions: Object.freeze({ ...(permissions ?? {}) }),
    sites: Object.freeze({}),
    subscription: UserSubscription.Offline,
  })
}

export function createStarterActor(
  actorId: UserId,
  permissions?: PermissionsRecord,
  lastIp: string | null = null,
): Actor {
  const currentSite = getSiteIdFromSiteNumber(1)
  return Object.freeze({
    currentSite: getSiteIdFromSiteNumber(1),
    id: actorId,
    permissions: Object.freeze({ ...(permissions ?? {}) }),
    sites: Object.freeze({
      [currentSite]: Object.freeze({
        id: currentSite,
        lastConnection: new Date().toISOString(),
        lastIp,
      }),
    }),
    subscription: UserSubscription.Starter,
  })
}

export function isUserSubscription(str: string): str is UserSubscription {
  return Object.values(UserSubscription).includes(str as UserSubscription)
}
