import { FC, SVGProps } from 'react'

const HomeIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.8,2.9l-6,4.5c-0.5,0.38,-0.8,0.97,-0.8,1.6v9c0,1.1,0.9,2,2,2h4v-7h4v7h4c1.1,0,2,-0.9,2,-2v-9c0,-0.63,-0.3,-1.22,-0.8,-1.6l-6,-4.5c-0.71,-0.53,-1.69,-0.53,-2.4,0z" />
    </svg>
  )
}

export default HomeIcon
