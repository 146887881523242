import { withAuthenticationRequired } from '@auth0/auth0-react'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ResourceNotFound from './components/ResourceNotFound'
import {
  ResourceEditorType,
  buildAppRouteLink,
  buildAppRoutePathSet,
  buildResourceRoutePath,
} from './components/routes'
import LayoutEditor from './editors/LayoutEditor'
import SidebarMenu from './menus/SidebarMenu'
import { SidebarStateProvider } from './menus/SidebarStateProvider'
import TopBar from './menus/TopBar'
import ProjectIndex from './projects'
import { OperationHandlerProvider } from './store/OperationHandlerProvider'

const App: FC = () => {
  const { path } = useRouteMatch()

  return (
    <OperationHandlerProvider>
      <SidebarStateProvider>
        <TopBar />
        <div className="content-area flex">
          <SidebarMenu />
          <main className="w-full">
            <Switch>
              <Route exact path={path}>
                <Redirect
                  to={buildAppRouteLink({
                    editor: ResourceEditorType.Project,
                    projectId: 'drafts',
                  })}
                />
              </Route>
              <Route exact path={path}>
                <div className="flex flex-row items-center justify-center pt-24">
                  <div className="w-96 h-96 p-16 mx-auto bg-secondary bg-opacity-10 rounded-full">
                    <div className="w-64 h-64 flex flex-col justify-center space-y-2">
                      <h6 className="tpg-title-6 text-center">
                        <FormattedMessage
                          defaultMessage="The home page is currently empty"
                          id="app.folder-view.empty-home-header"
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </Route>
              <Route path={buildAppRoutePathSet(ResourceEditorType.Project)}>
                <ProjectIndex />
              </Route>
              <Route
                path={buildResourceRoutePath({
                  editor: ResourceEditorType.Layout,
                })}
              >
                <LayoutEditor />
              </Route>
              <Route>
                <ResourceNotFound />
              </Route>
            </Switch>
          </main>
        </div>
      </SidebarStateProvider>
    </OperationHandlerProvider>
  )
}

export default withAuthenticationRequired(App)
