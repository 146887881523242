import { useAuth0 } from '@auth0/auth0-react'
import { FC, useEffect, useMemo, useState } from 'react'
import {
  Provider as ReduxProvider,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux'
import { Store, applyMiddleware, createStore } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { EntityID } from '../../../core/entities/Identificable'
import { UserId } from '../../../core/entities/SID'
import {
  AppState,
  buildInitialAppState,
} from '../../../core/state/AppOperation'
import {
  AppAction,
  operationReducer,
} from '../../../core/state/OperationReducer'
import { composeJsonReviver } from '../../../core/utils/composeJsonReviver'

const localStorageStateKey = 'cardigraph_app_state'

export type AppDispatch = ThunkDispatch<AppState, unknown, AppAction>
// export type AppDispatch = Dispatch<AppOperation>

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const useAppStore: () => Store<AppState, AppAction> = useStore

export const ReduxStoreProvider: FC = ({ children }) => {
  const { user: auth0User } = useAuth0()
  if (!auth0User?.sub) {
    throw new Error(
      'User ID not found. User must be logged in to initialize application',
    )
  }
  const [actorId] = useState<UserId>(auth0User.sub)
  const reduxStore: Store<AppState, AppAction> = useMemo(() => {
    return createStore(
      function rootReducer(
        state: AppState | undefined,
        action: AppAction,
      ): AppState {
        if (typeof state === 'undefined') {
          return buildInitialAppState(actorId)
        } else {
          return operationReducer(state, action)
        }
      },
      retrieveStateFromLocalStorage(actorId) as never,
      applyMiddleware(thunk),
    )
  }, [actorId])

  useEffect(() => {
    return reduxStore.subscribe(function saveStateToLocalStorage(): void {
      localStorage.setItem(
        localStorageStateKey,
        JSON.stringify(reduxStore.getState()),
      )
    })
  }, [reduxStore])

  return <ReduxProvider store={reduxStore}>{children}</ReduxProvider>
}

function retrieveStateFromLocalStorage(actorId: UserId): AppState {
  const storedState = localStorage.getItem(localStorageStateKey)
  if (storedState) {
    const state = JSON.parse(
      storedState,
      composeJsonReviver([EntityID.jsonReviver]),
    ) as AppState

    // If somehow we find data from a different user, delete it to avoid
    // personal data leaks and inconsistent states
    if (state.currentActor.id === actorId) {
      console.log('retrieving state=', state)
      return state
    }
  }
  const state = buildInitialAppState(actorId)
  localStorage.setItem(localStorageStateKey, JSON.stringify(state))
  return state
}
