import { Popover } from '@headlessui/react'
import classNames from 'classnames'
import {
  FC,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'
import AssetUploadIcon from '../../icons/AssetUploadIcon'
import CardLayoutIcon from '../../icons/CardLayoutIcon'
import CardsIcon from '../../icons/CardsIcon'
import DeckIcon from '../../icons/DeckIcon'
import FolderAddIcon from '../../icons/FolderAddIcon'
import PeopleIcon from '../../icons/PeopleIcon'
import PlusIcon from '../../icons/PlusIcon'
import TableIcon from '../../icons/TableIcon'
import NewDocumentDialog, { NewDocumentType } from './NewDocumentDialog'

interface NewDocumentListItemProps {
  disabled?: boolean
  icon?: ReactElement
  label: ReactElement | string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const NewDocumentListItem: FC<NewDocumentListItemProps> = ({
  disabled,
  icon,
  label,
  onClick,
}) => {
  return (
    <li className="w-full">
      <button
        className="focusable-wrapper h-8 w-full block disabled:opacity-50 disabled:cursor-default"
        disabled={disabled}
        onClick={onClick}
        type="button"
      >
        <div
          className={classNames(
            'focusable h-full flex items-center tpg-list-2 px-3',
            !disabled && 'hover:bg-primary hover:bg-opacity-20',
            'ring-primary ring-opacity-80 ring-inset',
            !disabled && 'active:bg-primary active:bg-opacity-50 active:ring-0',
          )}
          tabIndex={-1}
        >
          <div className="flex-none h-8 w-8">{icon}</div>
          <div className="mx-1 sentence-case">{label}</div>
        </div>
      </button>
    </li>
  )
}

const NewDocumentFab: FC = () => {
  const fabRef = useRef<HTMLButtonElement>(null)

  const [
    selectedDocumentType,
    setSelectedDocumentType,
  ] = useState<NewDocumentType>(undefined /* 'asset' */)

  const closeDialog = useCallback(() => {
    setSelectedDocumentType(undefined)
  }, [])

  useEffect(() => {
    // This is done so that when closed the focus goes back to the FAB
    const buttonReference = fabRef.current
    if (typeof selectedDocumentType !== undefined) {
      return () => buttonReference?.focus()
    }
    return undefined
  }, [selectedDocumentType])

  const [listState] = useState([
    {
      icon: <FolderAddIcon className="m-1" />,
      label: (
        <FormattedMessage
          defaultMessage="Folder"
          id="app.document-type.folder"
        />
      ),
      type: 'folder' as const,
    },
    {
      icon: <CardLayoutIcon className="m-1" />,
      label: (
        <FormattedMessage
          defaultMessage="Layout"
          id="app.document-type.layout"
        />
      ),
      type: 'layout' as const,
    },
    {
      icon: <TableIcon className="m-1" />,
      label: (
        <FormattedMessage
          defaultMessage="Data source"
          id="app.document-type.data-source"
        />
      ),
      type: 'data-source' as const,
    },
    {
      icon: <AssetUploadIcon className="m-1" />,
      label: (
        <FormattedMessage
          defaultMessage="Upload {document}"
          id="app.document-type.upload-document"
          values={{
            document: (
              <FormattedMessage
                defaultMessage="Asset"
                id="app.document-type.asset"
              />
            ),
          }}
        />
      ),
      type: 'asset' as const,
    },
    {
      disabled: true,
      icon: <DeckIcon className="m-1" />,
      label: (
        <FormattedMessage defaultMessage="Deck" id="app.document-type.deck" />
      ),
      type: 'deck' as const,
    },
    {
      icon: <CardsIcon className="m-1" />,
      label: (
        <FormattedMessage
          defaultMessage="Project"
          id="app.document-type.project"
        />
      ),
      type: 'project' as const,
    },
    {
      disabled: true,
      icon: <PeopleIcon className="m-1" />,
      label: (
        <FormattedMessage defaultMessage="Team" id="app.document-type.team" />
      ),
      type: 'team' as const,
    },
  ])

  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className="focusable-wrapper m-1 rounded-full block"
              ref={fabRef}
            >
              <div
                className={classNames(
                  'focusable h-12 icon-btn-secondary flex items-center',
                  !open && 'elevation-dp6',
                )}
                tabIndex={-1}
              >
                <div className="h-12 w-12 p-3 flex-none">
                  <PlusIcon className="text-secondary h-6 w-6" />
                </div>
                <div className="tpg-app-title flex-none ml-2 mr-6">
                  <FormattedMessage
                    defaultMessage="New"
                    id="app.new-document-fab"
                  />
                </div>
              </div>
            </Popover.Button>
            <Popover.Panel
              className="absolute bg-surface rounded-lg py-2 w-60 -ml-4 -mt-14 elevation-dp8 z-30"
              focus={true}
            >
              <ul className="flex flex-col">
                {listState.map((listItem) => {
                  return (
                    <NewDocumentListItem
                      disabled={listItem.disabled}
                      icon={listItem.icon}
                      key={listItem.type}
                      label={listItem.label}
                      onClick={() => setSelectedDocumentType(listItem.type)}
                    />
                  )
                })}
              </ul>
            </Popover.Panel>
          </>
        )}
      </Popover>
      <NewDocumentDialog
        close={closeDialog}
        documentType={selectedDocumentType}
      />
    </>
  )
}

export default NewDocumentFab
