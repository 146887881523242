export type VectorClock = {
  readonly [key: string]: number
}

export function included(lhs: VectorClock, rhs: VectorClock): boolean {
  const keys = Object.keys({ ...lhs, ...rhs })
  return keys.every((key) => (lhs[key] ?? 0) <= (rhs[key] ?? 0))
}

export function happenedBefore(lhs: VectorClock, rhs: VectorClock): boolean {
  const keys = Object.keys({ ...lhs, ...rhs })
  return (
    keys.every((key) => (lhs[key] ?? 0) <= (rhs[key] ?? 0)) &&
    keys.some((key) => (lhs[key] ?? 0) < (rhs[key] ?? 0))
  )
}

/**
 *
 * @returns '0'  if lhs and rhs are equal
 *          '1'  if lhs < rhs
 *          '-1' if lhs > rhs
 */
export function compare(lhs: VectorClock, rhs: VectorClock): -1 | 0 | 1 {
  const keyList = Object.keys({ ...lhs, ...rhs })
  for (const key of keyList) {
    const diff = (rhs[key] ?? 0) - (lhs[key] ?? 0)
    if (diff > 0) return 1
    else if (diff < 0) return -1
  }

  return 0
}

export function mergeClocks(lhs: VectorClock, rhs: VectorClock): VectorClock {
  const result = { ...lhs, ...rhs }
  for (const key in lhs) {
    if (lhs[key] > result[key]) result[key] = lhs[key]
  }
  return Object.freeze(result)
}
