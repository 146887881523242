import classNames from 'classnames'
import { ButtonHTMLAttributes, FC } from 'react'

const ButtonPrimary: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        'focusable-wrapper bg-primary block rounded-lg',
        'disabled:opacity-50 disabled:cursor-not-allowed',
        className,
      )}
      disabled={disabled}
    >
      <div
        className={classNames(
          'focusable w-full h-full flex items-center rounded-lg py-2 px-4',
          !disabled && 'shadow',
          'tpg-list-1 uppercase tracking-wider',
          !disabled && 'hover:bg-surface hover:bg-opacity-20',
          'ring-secondary ring-opacity-80 ring-inset',
          !disabled &&
            'active:bg-surface active:bg-opacity-50 active:ring-0 active:shadow-none',
        )}
        tabIndex={-1}
      >
        {children}
      </div>
    </button>
  )
}

export default ButtonPrimary
