import { useAuth0 } from '@auth0/auth0-react'
import { FC } from 'react'
import UserAvatar from './UserAvatar'

const UserPlaque: FC = () => {
  const { user } = useAuth0()

  const name = user?.preferred_username || user?.name || user?.email
  const email = user?.email

  return (
    <div className="p-4 border-b border-secondary border-opacity-10">
      <div className="mb-4">
        <UserAvatar size="m" />
      </div>
      <div className="tpg-app-title">{name}</div>
      {name !== email && (
        <div className="tpg-menu-2 text-opacity-70">{email}</div>
      )}
    </div>
  )
}

export default UserPlaque
