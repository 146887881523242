import { EntityID, EntityType, Identificable } from './Identificable'

export enum MeasurementUnit {
  mm = 'mm',
  in = 'in',
  px = 'px',
}

export interface Dimensions {
  height: number
  width: number
  // units: MeasurementUnits
}

export interface Layout extends Identificable<EntityType.Layout> {
  back: EntityID<EntityType.LayoutComponent> | null
  format: CardFormat
  front: EntityID<EntityType.LayoutComponent>
  size: Dimensions
}

export enum CardFormat {
  Custom = 'custom',
  Standard = 'standard',
  Tarot = 'tarot',
  European = 'european',
  MiniEuropean = 'mini-european',
  American = 'american',
  MiniAmerican = 'mini-american',
  Square = 'square',
  MiniSquare = 'mini-square',
}

export function isCardFormat(str: string): str is CardFormat {
  return Object.values(CardFormat).includes(str as CardFormat)
}

export const DEFAULT_CARD_SIZES: Record<CardFormat, Dimensions> = Object.freeze(
  {
    [CardFormat.Custom]: { height: 88.9, width: 63.5 },
    [CardFormat.Standard]: { height: 88.9, width: 63.5 },
    [CardFormat.Tarot]: { height: 120, width: 70 },
    [CardFormat.European]: { height: 92, width: 59 },
    [CardFormat.MiniEuropean]: { height: 68, width: 44 },
    [CardFormat.American]: { height: 88.9, width: 57.15 },
    [CardFormat.MiniAmerican]: { height: 63, width: 41 },
    [CardFormat.Square]: { height: 70, width: 70 },
    [CardFormat.MiniSquare]: { height: 50, width: 50 },
  },
)

export function createLayout(
  id: EntityID<EntityType.Layout>,
  front: EntityID<EntityType.LayoutComponent>,
): Layout {
  return Object.freeze({
    back: null,
    format: CardFormat.Standard,
    front,
    id,
    size: DEFAULT_CARD_SIZES[CardFormat.Standard],
  })
}
