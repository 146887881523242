import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePopper } from 'react-popper'
import LanguageSelectorIcon from '../../icons/LanguageSelectorIcon'
import MenuIcon from '../../icons/MenuIcon'
import MenuOpenIcon from '../../icons/MenuOpenIcon'
import { useI18n } from '../../infrastructure/i18n/I18nProvider'
import { useSidebarState } from './SidebarStateProvider'
import UserProfileMenu from './UserProfileMenu'

const LanguageIconSelector: FC = () => {
  const { availableLocales, currentLocale, setLocale } = useI18n()
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(
    null,
  )
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 8 } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  })

  return (
    <div className="flex-none ml-auto relative">
      <Listbox onChange={setLocale} value={currentLocale}>
        {({ open }) => (
          <>
            <Listbox.Button
              className="focusable-wrapper m-1 rounded-full"
              ref={setReferenceElement}
            >
              <div
                className={classNames(
                  'focusable h-12 w-12 p-3 icon-btn-primary',
                  open && 'bg-surface bg-opacity-50 hover:bg-opacity-50',
                )}
                tabIndex={-1}
              >
                <LanguageSelectorIcon />
              </div>
            </Listbox.Button>
            <Listbox.Options
              className={classNames(
                'popper-container',
                'absolute bg-surface rounded-lg elevation-dp8 w-24 z-10 py-2',
                'focus:outline-none',
              )}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <div
                className="popper-arrow"
                ref={setArrowElement}
                style={styles.arrow}
              />
              {availableLocales.map(({ code, ownDisplayName }) => (
                <Listbox.Option
                  className={({ active }) =>
                    classNames(
                      'tpg-menu-2 px-4 h-8 capitalize',
                      active && 'bg-primary bg-opacity-20',
                    )
                  }
                  key={code}
                  value={code}
                >
                  {({ selected }) => (
                    <div
                      className={classNames(
                        'h-full w-full cursor-default flex items-center',
                        selected && 'tpg-menu-1',
                      )}
                    >
                      {ownDisplayName}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  )
}

const TopBar: FC = () => {
  const { isFixed, isOpen, toggle } = useSidebarState()

  return (
    <header className="bg-primary flex h-14 elevation-dp4">
      <button
        className="focusable-wrapper m-1 rounded-full disabled:opacity-50"
        disabled={isFixed}
        onClick={toggle}
      >
        <div
          className={classNames(
            'focusable p-3',
            isFixed ? 'icon-btn-primary-disabled' : 'icon-btn-primary',
          )}
          tabIndex={-1}
        >
          {isOpen ? (
            <MenuOpenIcon className="h-6 w-6 text-secondary self-center" />
          ) : (
            <MenuIcon className="h-6 w-6 text-secondary self-center" />
          )}
        </div>
      </button>
      <div className="flex flex-none mx-4 space-x-2">
        <img className="h-8 self-center" src="/static/logo.svg" />
        <h1 className="hidden md:inline-block tpg-app-title self-center">
          <FormattedMessage
            defaultMessage="Cardigraph"
            description="App title"
            id="app-title"
          />
        </h1>
      </div>
      <div className="flex-none ml-auto">
        <div className="flex">
          <LanguageIconSelector />
          <UserProfileMenu />
        </div>
      </div>
    </header>
  )
}

export default TopBar
