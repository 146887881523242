import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  RemoteResponse,
  parseRemoteResponse,
} from '../../../core/communication/remote'
import { Operation } from '../../../core/entities/Operation'
import { getMetaProjectId } from '../../../core/state/AppOperation'
import { OperationBroadcaster } from '../../../core/state/OperationBroadcaster'
import { OperationHandler } from '../../../core/state/OperationHandler'
import { selectCurrentActorId } from '../../../core/state/appSelectors'
import {
  ReduxStoreProvider,
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from './ReduxStoreProvider'
// Re-exports
export { useAppDispatch, useAppSelector } from './ReduxStoreProvider'

const OperationContext = createContext<OperationHandler | undefined>(undefined)

declare global {
  interface Window {
    CARDIGRAPH_POLLING_ACTIVE: boolean
  }
}
window['CARDIGRAPH_POLLING_ACTIVE'] = true

const InnerOperationProvider: FC = ({ children }) => {
  const { getAccessTokenSilently, user: auth0User } = useAuth0()
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const isInitialized = useRef<boolean>(false)
  if (!auth0User?.sub) {
    throw new Error(
      'User ID not found. User must be logged in to initialize application',
    )
  }
  const currentActorId = useAppSelector(selectCurrentActorId)

  const broadcaster = useCallback<OperationBroadcaster>(
    async function (operation: Operation | Operation[]): Promise<void> {
      const outgoingOperations = Array.isArray(operation)
        ? operation
        : [operation]

      await getAccessTokenSilently({
        audience: 'https://cardigraph.com/api/',
      })
        .then((accessToken) =>
          axios.put<RemoteResponse>(`/api/operation/`, outgoingOperations, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            transformResponse: [parseRemoteResponse],
          }),
        )
        .then((result) => {
          console.log('BROADCASTED=', operation)
          console.log('BROADCASTING RESULT=', result.data)
          result.data.operations.forEach((incomingOperation) => {
            dispatch(incomingOperation)
          })
          if (typeof result.data.errors !== 'undefined') {
            const errors = result.data.errors
            Object.keys(errors).forEach((errorOperationId) => {
              const failingOperation = outgoingOperations.find(
                ({ id }) => id.toString() === errorOperationId,
              )
              console.warn(
                `Broadcasted Operation [${errorOperationId}] failed due to: "${errors[errorOperationId]}"`,
                failingOperation,
              )
            })
          }
        })
        .catch((error: Error) => {
          console.log(`'operationBroadcast' failure:`, error.message)
        })
    },
    [dispatch, getAccessTokenSilently],
  )

  useEffect(() => {
    return store.subscribe(() => {
      const { outbox } = store.getState()
      if (outbox.length) {
        void broadcaster(outbox)
        store.dispatch({ type: 'empty-outbox' })
      }
    })
  }, [broadcaster, store])

  const handler = useMemo(() => {
    if (isInitialized.current) {
      console.warn('WARNING: Re-rendering OperationHandlerProvider')
    }

    return OperationHandler(broadcaster)
  }, [broadcaster])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const poll = useRef<NodeJS.Timeout>()
  const boopUser = useCallback(
    async function (): Promise<void> {
      const boopDelay = 10000
      if (poll.current) {
        clearTimeout(poll.current)
      }
      poll.current = undefined
      if (window['CARDIGRAPH_POLLING_ACTIVE']) {
        // console.log('polling...')
        window['CARDIGRAPH_POLLING_ACTIVE'] = false
        await dispatch(
          handler.createLocalOperation(
            'boop:user',
            getMetaProjectId(currentActorId),
            null,
          ),
        )
      }
      poll.current = setTimeout(() => void boopUser(), boopDelay)
    },
    [currentActorId, dispatch, handler],
  )

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true
      void boopUser().then(() => {
        setIsLoading(false)
      })
    }
  }, [boopUser])

  return (
    <OperationContext.Provider value={handler}>
      {isLoading ? 'Loading...' : children}
    </OperationContext.Provider>
  )
}

export const OperationHandlerProvider: FC = ({ children }) => {
  return (
    <ReduxStoreProvider>
      <InnerOperationProvider>{children}</InnerOperationProvider>
    </ReduxStoreProvider>
  )
}

export function useOperationHandler(): OperationHandler {
  const context = useContext(OperationContext)
  if (typeof context === 'undefined') {
    throw new Error(
      '`useOperationHandler` must be used within a OperationHandlerProvider',
    )
  }
  return context
}
