import { useMemo } from 'react'
import { FileDocument, Folder } from '../../../core/services/FileElement'
import { Project } from '../../../core/state/Project'
import {
  makeSelectResource,
  selectDraftsProject,
} from '../../../core/state/appSelectors'
import { useAppSelector } from '../store/OperationHandlerProvider'
import { useAppRouteMatch } from './routes'

export interface ResourceData {
  project: Project
  resource: FileDocument | Folder | null
}

export function useCurrentResource(defaultToDrafts: true): ResourceData
export function useCurrentResource(
  defaultToDrafts?: boolean,
): ResourceData | undefined
export function useCurrentResource(
  defaultToDrafts?: boolean,
): ResourceData | undefined {
  // Default value
  const drafts = useAppSelector(selectDraftsProject)
  const defaultValue = useMemo<ResourceData | undefined>(
    () =>
      defaultToDrafts
        ? {
            project: drafts,
            resource: null,
          }
        : undefined,
    [defaultToDrafts, drafts],
  )
  // Match to get actual value
  const match = useAppRouteMatch()

  const selectCurrentResource = useMemo(
    () => makeSelectResource(match?.resourceId, match?.projectId),
    [match?.resourceId, match?.projectId],
  )
  const resource = useAppSelector(selectCurrentResource)

  return resource ?? defaultValue
}
