import { FC, useMemo } from 'react'
// import { useRouteMatch } from 'react-router-dom'
import { FileDocumentType } from '../../../core/services/FileElement'
import { makeSelectFolderChildren } from '../../../core/state/appSelectors'
import ResourceNotFound from '../components/ResourceNotFound'
import UnimplementedResourceView from '../components/UnimplementedResourceView'
// import { useCurrentProject } from '../components/useCurrentProject'
import {
  // ResourceData,
  useCurrentResource,
} from '../components/useCurrentResource'
import { useAppSelector } from '../store/OperationHandlerProvider'
import FolderView from './FolderView'
// import LayoutEditor from './LayoutEditor'
import ProjectsBreadcrumb from './ProjectsBreadcrumb'

// const ResourceViewSelector: FC<{ resource: ResourceData['resource'] }> = ({
//   resource,
// }) => {
//   console.log('ResourceViewSelector=', resource)
//   if (!resource) {
//     return <UnimplementedResourceView />
//   }
//   switch (resource.documentType) {
//     case FileDocumentType.Layout:
//       return <LayoutEditor resource={resource} />
//     default:
//       return <UnimplementedResourceView />
//   }
// }

const ProjectIndex: FC = () => {
  // const match = useRouteMatch()
  const currentResource = useCurrentResource()
  // console.log('MATCH=', match, currentResource)

  const selectResourceChildren = useMemo(() => {
    if (!currentResource) {
      return () => undefined
    } else {
      const { project, resource } = currentResource
      if (!resource) {
        return () =>
          Object.values(project.documents).filter(({ parentId }) => !parentId)
      } else if (resource.documentType === FileDocumentType.Folder) {
        return makeSelectFolderChildren(resource)
      } else {
        return () => undefined
      }
    }
  }, [currentResource])
  const resourceChildren = useAppSelector(selectResourceChildren)

  return (
    <>
      <ProjectsBreadcrumb />
      {resourceChildren ? (
        <FolderView isProject={!currentResource?.resource}>
          {resourceChildren}
        </FolderView>
      ) : currentResource?.resource ? (
        <UnimplementedResourceView />
      ) : (
        <ResourceNotFound />
      )}
    </>
  )
}

export default ProjectIndex
