import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Footer from './Footer'
import FrontPage from './FrontPage'
import NavBar from './NavBar'
import NotFoundErrorPage from './NotFoundErrorPage'

const LandingRouting: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <div className="text-secondary min-h-full max-w-7xl mx-8 xl:mx-auto">
      <NavBar />
      <main>
        <Switch>
          <Route exact path="/">
            {isAuthenticated ? <Redirect to="/app" /> : <FrontPage />}
          </Route>
          <Route path="/front-page">
            <FrontPage />
          </Route>
          {/* <Route path="/blog">
            <Blog />
          </Route> */}
          <Route path={['/artifacts', '/static']}>
            <NotFoundErrorPage />
          </Route>
          <Route>
            <NotFoundErrorPage />
          </Route>
        </Switch>
      </main>
      <Footer />
    </div>
  )
}

export default LandingRouting
