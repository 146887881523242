import { useAuth0 } from '@auth0/auth0-react'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { StylableComponentProps } from '../../utils/StylableProps'

const LogoutButton: React.FC<StylableComponentProps> = ({ className }) => {
  const { logout } = useAuth0()
  const logoutFn = useCallback(() => {
    return logout({ returnTo: window.location.origin })
  }, [logout])

  return (
    <button
      className={classNames('focusable-wrapper h-full w-full block', className)}
      type="button"
      onClick={logoutFn}
    >
      <div className="focusable" tabIndex={-1}>
        <FormattedMessage
          defaultMessage="Logout"
          description="Logout menu link"
          id="auth.logout"
        />
      </div>
    </button>
  )
}

export default LogoutButton
