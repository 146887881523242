import { FC, SVGProps } from 'react'

const CardsIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.4524,4.70413l4.806,1.378c0.51,0.146,0.941,0.489,1.198,0.953c0.257,0.464,0.32,1.011,0.173,1.521l-2.756,9.613c-0.146,0.509,-0.489,0.94,-0.953,1.198c-0.464,0.257,-1.011,0.319,-1.521,0.173l-5.767,-1.654l5.357,-0.544c0.255,0.073,0.529,0.042,0.761,-0.087c0.232,-0.129,0.403,-0.344,0.476,-0.599l2.205,-7.69c0.074,-0.255,0.042,-0.529,-0.086,-0.76c-0.129,-0.232,-0.344,-0.404,-0.599,-0.477l-2.884,-0.827l-0.41,-2.198z
           M7,6a2,2,0,0,1,2,-2h6a2,2,0,0,1,2,2v10a2,2,0,0,1,-2,2h-2l1,-2a1,1,0,0,0,1,-1v-8a1,1,0,0,0,-1,-1h-7z
           M2.50782,9.03675c-0.146,-0.51,-0.084,-1.057,0.173,-1.521c0.257,-0.464,0.688,-0.807,1.198,-0.953l5.768,-1.654c0.51,-0.146,1.057,-0.083,1.521,0.174c0.464,0.257,0.806,0.688,0.953,1.198l2.756,9.612c0.146,0.51,0.084,1.057,-0.173,1.521c-0.257,0.464,-0.688,0.807,-1.198,0.953l-5.768,1.654c-0.51,0.146,-1.057,0.084,-1.521,-0.173c-0.464,-0.258,-0.806,-0.688,-0.953,-1.198l-2.756,-9.613zm2.198,0.41l2.205,7.69c0.073,0.255,0.245,0.47,0.477,0.599c0.232,0.129,0.505,0.16,0.76,0.087l3.845,-1.103c0.255,-0.073,0.471,-0.244,0.599,-0.476c0.129,-0.232,0.16,-0.506,0.087,-0.761l-2.205,-7.69c-0.073,-0.255,-0.245,-0.47,-0.477,-0.599c-0.232,-0.128,-0.505,-0.16,-0.76,-0.086l-3.845,1.102c-0.255,0.073,-0.471,0.245,-0.599,0.477c-0.129,0.232,-0.16,0.505,-0.087,0.76z
          "
      />
    </svg>
  )
}

export default CardsIcon
