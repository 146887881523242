import { Dialog } from '@headlessui/react'
import classNames from 'classnames'
import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FormattedMessage, defineMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { EntityType } from '../../../core/entities/Identificable'
import { FileDocumentType } from '../../../core/services/FileElement'
import {
  getMetaProjectId,
  isDraftsProject,
} from '../../../core/state/AppOperation'
import { ProjectID } from '../../../core/state/Project'
import { selectCurrentActorId } from '../../../core/state/appSelectors'
import ErrorIcon from '../../icons/ErrorIcon'
import SpinnerIcon from '../../icons/SpinnerIcon'
import ButtonPrimary from '../components/ButtonPrimary'
import ButtonSecondary from '../components/ButtonSecondary'
import { ResourceEditorType, buildAppRouteLink } from '../components/routes'
import { useCurrentResource } from '../components/useCurrentResource'
import {
  useAppDispatch,
  useAppSelector,
  useOperationHandler,
} from '../store/OperationHandlerProvider'
import UploadResource from './UploadResource'

export type NewDocumentType =
  | 'asset'
  | 'data-source'
  | 'deck'
  | 'folder'
  | 'layout'
  | 'project'
  | 'team'
  | undefined

defineMessage({
  defaultMessage: 'Document',
  id: 'app.document-type.undefined',
})

interface NewDocumentDialogProps {
  close: () => void
  documentType: NewDocumentType
}

const NewDocumentDialog: FC<NewDocumentDialogProps> = ({
  close,
  documentType,
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { createLocalOperation } = useOperationHandler()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const currentActorId = useAppSelector(selectCurrentActorId)

  const placeholder = intl.formatMessage(
    {
      defaultMessage: 'Untitled {document}',
      id: 'app.new-document-dialog.placeholder',
    },
    {
      document: intl.formatMessage({
        id: `app.document-type.${documentType as string}`,
      }),
    },
  )
  const [documentName, setName] = useState<string>(placeholder)
  const handleNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setName(event.target.value)
    },
    [],
  )
  useEffect(() => {
    setName(placeholder)
  }, [placeholder])

  const cleanup = useCallback(() => {
    setIsProcessing(false)
    setName(placeholder)
    close()
  }, [close, placeholder])

  const history = useHistory()

  const currentResource = useCurrentResource(true)
  const goToProjectPage = useCallback(
    (projectId: ProjectID) => {
      history.push(
        buildAppRouteLink({
          editor: ResourceEditorType.Project,
          projectId: projectId.toRelativeId(),
        }),
      )
    },
    [history],
  )
  const goToResourcePage = useCallback(
    (resourcePath: string, holderId: ProjectID) => {
      const holderRelativeId = isDraftsProject(holderId)
        ? 'drafts'
        : holderId.toRelativeId()
      history.push(
        buildAppRouteLink({
          editor: ResourceEditorType.Project,
          projectId: holderRelativeId,
          resourceId: resourcePath,
        }),
      )
    },
    [history],
  )

  const holderId = currentResource.project.id
  const parentId =
    currentResource.resource?.documentType === FileDocumentType.Folder
      ? currentResource.resource.id
      : currentResource.resource?.parentId ?? null

  const createDocumentCallbacks = useMemo(
    () =>
      Object.freeze({
        'asset': () => {
          // const op = {}
          // setIsProcessing(true)
          // void dispatch(op)
          //   .then(cleanup)
          //   .then(() => goToResourcePage(op.payload.id, op.payload.parent))
        },
        'data-source': () => {
          void dispatch(
            createLocalOperation('create:document', holderId, {
              documentType: FileDocumentType.DataSource,
              name: documentName,
              parentId,
            }),
          ).then((op) => {
            cleanup()
            goToResourcePage(op.id.toRelativeId(), op.holderId)
          })
        },
        'deck': () => {
          undefined
        },
        'folder': () => {
          void dispatch(
            createLocalOperation('create:document', holderId, {
              documentType: FileDocumentType.Folder,
              name: documentName,
              parentId,
            }),
          ).then((op) => {
            cleanup()
            goToResourcePage(op.id.toRelativeId(), op.holderId)
          })
        },
        'layout': () => {
          void dispatch(
            createLocalOperation('create:layout', holderId, {
              documentType: FileDocumentType.Layout,
              name: documentName,
              parentId,
            }),
          ).then((op) => {
            cleanup()
            goToResourcePage(op.id.toRelativeId(), op.holderId)
          })
        },
        'project': () => {
          void dispatch(
            createLocalOperation(
              'create:project',
              getMetaProjectId(currentActorId),
              { name: documentName },
            ),
          ).then((op) => {
            cleanup()
            goToProjectPage(op.id.withDomain(EntityType.Project))
          })
        },
        'team': () => {
          undefined
        },
      } as const),
    [
      cleanup,
      createLocalOperation,
      currentActorId,
      holderId,
      dispatch,
      documentName,
      goToProjectPage,
      goToResourcePage,
      parentId,
    ],
  )

  return (
    <Dialog
      className="z-10 fixed inset-0 flex items-center justify-center overflow-y-auto"
      onClose={close}
      open={typeof documentType !== 'undefined'}
    >
      <Dialog.Overlay className="absolute inset-0 bg-black opacity-30" />
      <div className="absolute flex-none w-96 bg-surface rounded-lg elevation-dp12 p-6">
        <Dialog.Title className="tpg-title-6 sentence-case">
          <FormattedMessage
            defaultMessage="New {document}"
            id="app.new-document-dialog.title"
            values={{
              document: (
                <FormattedMessage
                  id={`app.document-type.${documentType as string}`}
                />
              ),
              documentType: documentType?.replace('-', ''),
            }}
          />
        </Dialog.Title>

        {documentType === 'asset' ? (
          <div className="my-4 h-48">
            <UploadResource />
          </div>
        ) : (
          <div className="my-4">
            <input
              aria-describedby="new-document-dialog-input-helper"
              autoComplete="off"
              className={classNames(
                'bg-white tpg-prop-label w-full rounded-lg shadow',
                'focus:outline-none focus:ring focus:ring-inset focus:ring-opacity-80',
                !documentName
                  ? 'border-red-700 focus:border-red-700 focus:ring-red-500'
                  : 'border-secondary focus:border-secondary focus:ring-primary',
              )}
              id="new-document-dialog-input"
              onChange={handleNameChange}
              placeholder={placeholder}
              required
              spellCheck={false}
              type="text"
              value={documentName}
            />
            <div
              className="tpg-list-2 block h-4 mt-1 text-red-800"
              id="new-document-dialog-input-helper"
            >
              {!documentName && (
                <>
                  <ErrorIcon className="inline-block w-4 h-4 -mt-0.5 mr-1" />
                  <span className="inline-block sentence-case">
                    <FormattedMessage
                      defaultMessage="{document} name cannot be empty"
                      id="app.new-document-dialog.input-helper-text"
                      values={{
                        document: (
                          <FormattedMessage
                            id={`app.document-type.${documentType as string}`}
                          />
                        ),
                      }}
                    />
                  </span>
                </>
              )}
            </div>
          </div>
        )}

        <div className="flex flex-row justify-end space-x-4">
          <ButtonSecondary onClick={close}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="app.dialog-utils.cancel"
            />
          </ButtonSecondary>
          <ButtonPrimary
            disabled={!documentName}
            onClick={documentType && createDocumentCallbacks[documentType]}
          >
            {isProcessing ? (
              <SpinnerIcon className="animate-spin -ml-1 mr-3 w-6 h-6 text-secondary" />
            ) : documentType === 'asset' ? (
              <FormattedMessage
                defaultMessage="Upload"
                id="app.dialog-utils.upload"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Create"
                id="app.dialog-utils.create"
              />
            )}
          </ButtonPrimary>
        </div>
      </div>
    </Dialog>
  )
}

export default NewDocumentDialog
