import { isObject } from '../utils/isObject'
import { SID, buildSID } from './SID'

export enum EntityType {
  Actor = 'actor',
  DataSource = 'data-source',
  Document = 'document',
  Layout = 'layout',
  LayoutComponent = 'layout-component',
  Operation = 'operation',
  Project = 'project',
  Unknown = 'unknown',
  User = 'user',
}

function isEntityType(str: string): str is EntityType {
  return Object.values(EntityType).includes(str as EntityType)
}

export type RelativeEntityID<
  T extends EntityType = EntityType
> = `${SID}~${EntityID<T>['nid']}`

export type IndexEntityID<
  T extends EntityType
> = `${EntityID<T>['domain']}/${RelativeEntityID<T>}`

const entityTypeRE = Object.values(EntityType).join('|')
const entityIdRegExpString = `^(?<domain>${entityTypeRE})/(?<userId>\\S*)~(?<site>\\w*)~(?<count>\\w*)$`
const relativeIdRegExpString = `^(?<userId>\\S*)~(?<site>\\w*)~(?<count>\\w*)$`

export class EntityID<T extends EntityType> {
  public readonly nid: string
  public readonly sid: SID
  // private readonly site: string

  constructor(
    public readonly ns: number, // sequence number
    public readonly domain: T,
    public readonly site: string,
    public readonly author: string,
  ) {
    this.nid = getCountFromSequenceNumber(ns)
    // this.site = getSiteIdFromSiteOrder(siteNumber)
    this.sid = buildSID(author, site)

    Object.freeze(this)
  }

  static fromRelativeId<T extends EntityType>(
    this: void,
    type: T,
    relativeId: string,
  ): EntityID<T> {
    // const match =
    //   new RegExp(entityIdRegExpString, 'ig').exec(relativeId) ??
    //   new RegExp(relativeIdRegExpString, 'ig').exec(relativeId)
    const match = new RegExp(relativeIdRegExpString, 'ig').exec(relativeId)
    const countStr = match?.groups?.['count']
    const siteStr = match?.groups?.['site']
    const userId = match?.groups?.['userId']

    if (!countStr || !siteStr || !userId) {
      throw new TypeError(
        `Trying to deserialize an EntityID with invalid format. String "${relativeId}" does not follow expected format /${relativeIdRegExpString}/`,
      )
    }

    if (!isEntityType(type)) {
      throw new TypeError(
        `Unrecognized EntityID 'domain': "${(type as unknown) as string}"`,
      )
    }

    const count = Number.parseInt(countStr, 36)
    if (!Number.isInteger(count)) {
      throw new TypeError(
        `Invalid EntityID 'count': "${countStr}" cannot be parsed as integer`,
      )
    }

    const site = Number.parseInt(siteStr, 36)
    if (!Number.isInteger(site)) {
      throw new TypeError(
        `Invalid EntityID 'count': "${siteStr}" cannot be parsed as integer`,
      )
    }

    return new EntityID(count, type, siteStr, userId)
  }

  static fromString(this: void, serializedId: string): EntityID<EntityType> {
    const match = new RegExp(entityIdRegExpString, 'ig').exec(serializedId)
    const countStr = match?.groups?.['count']
    const domain = match?.groups?.['domain']
    const siteStr = match?.groups?.['site']
    const userId = match?.groups?.['userId']

    if (!countStr || !domain || !siteStr || !userId) {
      throw new TypeError(
        `Trying to deserialize an EntityID with invalid format. String "${serializedId}" does not follow expected format /${entityIdRegExpString}/`,
      )
    }

    if (!isEntityType(domain)) {
      throw new TypeError(`Unrecognized EntityID 'domain': "${domain}"`)
    }

    const count = Number.parseInt(countStr, 36)
    if (!Number.isInteger(count)) {
      throw new TypeError(
        `Invalid EntityID 'count': "${countStr}" cannot be parsed as integer`,
      )
    }

    const site = Number.parseInt(siteStr, 36)
    if (!Number.isInteger(site)) {
      throw new TypeError(
        `Invalid EntityID 'count': "${siteStr}" cannot be parsed as integer`,
      )
    }

    return new EntityID(count, domain, siteStr, userId)
  }

  static jsonReviver(
    this: void,
    _: string,
    value: unknown,
  ): EntityID<EntityType> | undefined {
    if (isObject(value)) {
      if (
        value['__class_name'] === 'EntityID' &&
        typeof value['id'] === 'string'
      ) {
        return EntityID.fromString(value['id'])
      }
    }
    return undefined
  }

  // get ns(): number {
  //   return this.sequenceNumber
  // }

  [Symbol.toPrimitive](hint: 'default'): IndexEntityID<T>
  [Symbol.toPrimitive](hint: 'string'): IndexEntityID<T>
  [Symbol.toPrimitive](hint: 'number'): number
  [Symbol.toPrimitive](
    hint: 'default' | 'string' | 'number',
  ): IndexEntityID<T> | number {
    if (hint === 'number') {
      return NaN
    } else {
      return this.toString()
    }
  }

  isOfDomain<E extends EntityType>(domain: E): this is EntityID<E> {
    return (this as EntityID<EntityType>).domain === domain
  }

  toJSON(): { __class_name: string; id: string } {
    return {
      __class_name: 'EntityID',
      id: this.toString(),
    }
  }

  toRelativeId(): RelativeEntityID<T> {
    return `${this.author}~${this.site}~${this.nid}`
  }

  toString(): IndexEntityID<T> {
    return `${this.domain}/${this.toRelativeId()}`
  }

  withDomain<E extends EntityType>(domain: E): EntityID<E> {
    return new EntityID(this.ns, domain, this.site, this.author)
  }
}

export interface Identificable<T extends EntityType = EntityType> {
  readonly id: EntityID<T>
}

export function isIdentificable<T extends EntityType | undefined>(
  obj: unknown,
  type?: T,
): obj is Identificable<T extends undefined ? EntityType : T> {
  if (obj && typeof obj === 'object') {
    const rec: Record<string, unknown> = obj as Record<string, unknown>
    if (rec.id instanceof EntityID) {
      if (typeof type === 'undefined') {
        return true
      } else {
        return rec.id.isOfDomain(type)
      }
    }
  }
  return false
}

export class IdGenerator {
  private counter: number
  // private readonly siteId: string

  constructor(
    private readonly siteId: string = '001',
    private readonly userId: string = 'anon',
  ) {
    this.counter = 1
    // this.siteId = getSiteIdFromSiteOrder(siteOrder)
  }

  get sid(): SID {
    return `${this.userId}~${this.siteId}`
  }

  build(): Identificable<EntityType.Unknown>
  build<T extends EntityType>(domain: T): Identificable<T>
  build<T extends EntityType>(
    domain?: T,
  ): Identificable<T> | Identificable<EntityType.Unknown> {
    if (typeof domain === 'undefined') {
      return { id: this.next(EntityType.Unknown) }
    }
    return { id: this.next(domain) }
  }

  next(): EntityID<EntityType.Unknown>
  next<T extends EntityType>(domain: T): EntityID<T>
  next<T extends EntityType>(
    domain?: T,
  ): EntityID<T> | EntityID<EntityType.Unknown> {
    if (typeof domain === 'undefined') {
      return new EntityID(
        this.counter++,
        EntityType.Unknown,
        this.siteId,
        this.userId,
      )
    }
    return new EntityID(this.counter++, domain, this.siteId, this.userId)
  }
}

function getCountFromSequenceNumber(sequenceNumber: number): string {
  return sequenceNumber.toString(36).padStart(9, '0')
}
