import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { FC, ReactElement } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import ExpandLessIcon from '../../icons/ExpandLessIcon'
import ExpandMoreIcon from '../../icons/ExpandMoreIcon'
import { StylableComponentProps } from '../../utils/StylableProps'

interface SidebarMenuItemProps {
  disabled?: boolean
  icon?: ReactElement
  label: ReactElement | string
  link: string
  nested?: boolean
  strictMatch?: boolean
}

interface SidebarMenuButtonProps extends StylableComponentProps {
  disabled: boolean
  hasChildren: boolean
  link: string
}

const SidebarMenuButton: FC<SidebarMenuButtonProps> = ({
  disabled,
  children,
  className,
  hasChildren,
  link,
}) => {
  if (hasChildren) {
    return (
      <Disclosure.Button
        className={className}
        disabled={disabled}
        type="button"
      >
        {children}
      </Disclosure.Button>
    )
  } else {
    return (
      <Link className={className} to={link} type="button">
        {children}
      </Link>
    )
  }
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  disabled = false,
  children,
  icon,
  label,
  link,
  nested,
  strictMatch,
}) => {
  const match = useRouteMatch(link ?? '')
  console.log('SidebarMenuItem=', link, match)
  const selected =
    typeof link !== 'undefined' && match && (match.isExact || !strictMatch)
  const isParent = !!(
    children &&
    typeof link !== 'undefined' &&
    match &&
    !match.isExact
  )

  return (
    <li
      className={classNames(
        'w-full',
        nested ? 'pl-10 mt-0.5 mb-1' : 'py-1 px-2',
      )}
    >
      <Disclosure defaultOpen={isParent}>
        {({ open }) => (
          <>
            <SidebarMenuButton
              className={classNames(
                'focusable-wrapper w-full block rounded-lg',
                nested ? 'h-8' : 'h-10',
                disabled && 'opacity-50 pointer-events-none cursor-default',
              )}
              disabled={disabled}
              hasChildren={!!children}
              link={link}
            >
              <div
                className={classNames(
                  'focusable px-2 rounded-lg flex items-center h-full',
                  nested ? 'tpg-menu-2 text-opacity-70' : 'tpg-menu-1 py-1',
                  !selected &&
                    !disabled &&
                    'hover:bg-primary hover:bg-opacity-20',
                  'ring-primary ring-opacity-80',
                  !selected &&
                    'active:bg-primary active:bg-opacity-50 active:ring-0',
                  selected && 'bg-secondary text-primary',
                )}
                tabIndex={-1}
              >
                <div className={`flex-none ${nested ? 'h-6 w-6' : 'h-8 w-8'}`}>
                  {icon}
                </div>
                <div className="truncate mx-2">{label}</div>
                {children && !nested && (
                  <div className="flex-none h-8 w-8 p-1 ml-auto">
                    {open && !(disabled && !isParent) ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                )}
              </div>
            </SidebarMenuButton>
            {!(disabled && !isParent) && (
              <Disclosure.Panel>{children}</Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    </li>
  )
}

export default SidebarMenuItem
