import { FC, SVGProps } from 'react'

const ErrorIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM13.5 11.5a1.5 1.5 0 0 1 -3 0v-4a1.5 1.5 0 0 1 3 0zm0 5a1.5 1.5 0 0 1 -3 0a1.5 1.5 0 0 1 3 0z" />
    </svg>
  )
}

export default ErrorIcon
