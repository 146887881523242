import classNames from 'classnames'
import { FC, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { FileDocument, Folder } from '../../../core/services/FileElement'
import { Project } from '../../../core/state/Project'
import ArrowDownIcon from '../../icons/ArrowDownIcon'
import ChevronRightIcon from '../../icons/ChevronRight'
import {
  BuildResourceLinkParam,
  ResourceLinkData,
  buildResourceLink,
} from '../components/buildResourceLink'
import {
  ResourceData,
  useCurrentResource,
} from '../components/useCurrentResource'

type BreadcrumbItemProps = Omit<ResourceLinkData, 'id'> & {
  isCurrent?: boolean
}

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({
  icon: BreadcrumbIcon,
  isCurrent,
  link,
  name,
}) => {
  return (
    <>
      <Link className="focusable-wrapper block rounded-lg h-full" to={link}>
        <div
          className={classNames(
            'focusable pl-1 pr-2 rounded-lg flex flex-none items-center h-full w-full tpg-menu-1',
            !isCurrent && 'text-opacity-70',
            'hover:bg-primary hover:bg-opacity-20',
            'ring-primary ring-opacity-80 ring-offset-0',
            'active:bg-primary active:bg-opacity-50 active:ring-0',
          )}
          tabIndex={-1}
        >
          <div className="h-8 w-8 flex-none">
            <BreadcrumbIcon className="h-6 w-6 m-1" />
          </div>
          <div className="ml-2 flex-none">{name}</div>
        </div>
      </Link>
    </>
  )
}

type AncestorList = [Project, ...Folder[], Folder | FileDocument] | [Project]
function buildAncestorsList(resourceData: ResourceData): AncestorList {
  const { project, resource } = resourceData

  if (resource) {
    const folderList: Folder[] = []
    let parent =
      resource.parentId && project.documents[resource.parentId?.toRelativeId()]
    while (parent) {
      folderList.unshift(parent as Folder)
      parent =
        parent.parentId && project.documents[parent.parentId?.toRelativeId()]
    }

    return [project, ...folderList, resource]
  } else {
    return [project]
  }
}

const ProjectsBreadcrumb: FC = () => {
  const currentResource = useCurrentResource()

  const linkList: ResourceLinkData[] = useMemo(() => {
    const list: BuildResourceLinkParam[] = ['app']
    if (currentResource) {
      list.push(...buildAncestorsList(currentResource))
    }
    return list.map(buildResourceLink)
  }, [currentResource])
  const lastLinkIndex = linkList.length - 1

  return (
    <div
      className={classNames(
        'flex flex-row items-center h-12 p-2',
        'border-secondary border-opacity-10 border-b',
      )}
    >
      {linkList.map(({ icon, id, link, name }, idx) => (
        <Fragment key={id}>
          <BreadcrumbItem
            icon={icon}
            link={link}
            name={name}
            isCurrent={idx === lastLinkIndex}
          />
          {idx < lastLinkIndex && (
            <ChevronRightIcon className="text-secondary text-opacity-70 h-6 w-6 flex-none" />
          )}
        </Fragment>
      ))}
      <div className="h-8 w-8 flex-none">
        <ArrowDownIcon className="h-6 w-6 m-1 text-secondary text-opacity-50" />
      </div>
    </div>
  )
}

export default ProjectsBreadcrumb
