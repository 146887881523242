import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import App from './App'
import Landing from './Landing'
import MultiplayerPlayground from './MultiplayerPlayground'
import { AuthProvider } from './infrastructure/auth/AuthProvider'
import { I18nProvider } from './infrastructure/i18n/I18nProvider'
import './styles.css'

const Main: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <I18nProvider>
          <Switch>
            <Route path="/app">
              <App />
            </Route>
            <Route path="/storybook"></Route>
            <Route path="/multiplayer-playground">
              <MultiplayerPlayground />
            </Route>
            <Route path="/">
              <Landing />
            </Route>
          </Switch>
        </I18nProvider>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default Main
