type Comparator<T> = (lhs: T, rhs: T) => number

export class PriorityQueue<T> {
  private readonly compare: Comparator<T>
  private readonly heap: T[]

  constructor(
    comparator?: Comparator<T>,
    array?: T[],
    private readonly unique: boolean = true,
  ) {
    this.compare =
      typeof comparator !== 'undefined' ? comparator : defaultComparator
    this.heap = typeof array !== 'undefined' ? array : []
  }

  get root(): T | undefined {
    return this.heap[0]
  }

  get length(): number {
    return this.heap.length
  }

  pop(): T | undefined {
    if (this.heap.length > 1) {
      const root = this.heap[0]
      this.heap[0] = this.heap.pop() as T
      this.bubbleDown(0)
      return root
    } else if (this.heap.length === 1) {
      return this.heap.pop()
    } else {
      return undefined
    }
  }

  push(item: T): void {
    this.heap.push(item)
    this.bubbleUp(this.heap.length - 1)
  }

  private bubbleDown(current: number): void {
    const left = 2 * current + 1 // leftChild(current)
    const right = 2 * current + 2 // rightChild(current)
    let nextParent = current

    if (
      left < this.heap.length &&
      this.compare(this.heap[left], this.heap[nextParent]) > 0
    ) {
      nextParent = left
    }

    if (
      right < this.heap.length &&
      this.compare(this.heap[right], this.heap[nextParent]) > 0
    ) {
      nextParent = right
    }

    if (nextParent !== current) {
      this.swap(current, nextParent)
      this.bubbleDown(nextParent)
    }
  }

  private bubbleUp(current: number): void {
    if (current > 0) {
      const parent = Math.floor((current - 1) / 2)
      const comparation = this.compare(this.heap[current], this.heap[parent])
      if (comparation > 0) {
        this.swap(current, parent)
        this.bubbleUp(parent)
      } else if (this.unique && comparation === 0) {
        this.swap(current, this.heap.length - 1)
        this.heap.pop()
        if (current < this.heap.length) {
          this.bubbleDown(current)
        }
      }
    }
  }

  private swap(idx1: number, idx2: number): void {
    const tmp = this.heap[idx1]
    this.heap[idx1] = this.heap[idx2]
    this.heap[idx2] = tmp
  }
}

function defaultComparator<T>(lhs: T, rhs: T): number {
  if (typeof lhs === 'number' && typeof rhs === 'number') {
    return rhs - lhs
  } else if (typeof lhs === 'bigint' && typeof rhs === 'bigint') {
    return Number(rhs - lhs)
  } else {
    return String(lhs) < String(rhs) ? 1 : String(lhs) > String(rhs) ? -1 : 0
  }
}
