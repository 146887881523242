import { FC, SVGProps } from 'react'

const CardLayoutIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4,4 a2,2,0,0,1,2,-2 h11 a2,2,0,0,1,2,2 v17 a2,2,0,0,1,-2,2 h-11 a2,2,0,0,1,-2,-2 v-17z
           m2,0 v17h11v-17h-11z
           m1,1 h9v2h-9v-2z
           m0,10 h9v1h-9z
           m0,2 h9v1h-9z
           m0,2 h6v1h-6z
           M9.34143,10.16385l1.455,1.781l2.147,-2.808c0.139,-0.183,0.416,-0.183,0.555,0.007l2.431,3.293c0.173,0.232,0.007,0.563,-0.277,0.563h-8.306c-0.291,0,-0.45,-0.338,-0.27,-0.57l1.725,-2.252c0.131,-0.183,0.395,-0.19,0.54,-0.014v0z
          "
      />
    </svg>
  )
}

export default CardLayoutIcon
