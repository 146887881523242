import { produce } from 'immer'
import { EntityID, EntityType } from '../entities/Identificable'
import { Operation } from '../entities/Operation'
import { buildSID } from '../entities/SID'
import { VectorClock, included } from '../entities/VectorClock'
import { AppOperation } from './AppOperation'

export function clockCoversOperation(
  clock: VectorClock,
  operation: Readonly<Operation> | undefined,
): operation is Operation {
  if (typeof operation === 'undefined' || !operation.context) {
    return false
  }
  return included(operation.context, clock)
}

export function timestampOperation(
  clock: VectorClock,
  originUser: string,
  originSite: string,
  operation: Readonly<Omit<Operation, 'context' | 'id' | 'date'>>,
): AppOperation {
  const sid = buildSID(originUser, originSite)
  const ns = (clock[sid] ?? 0) + 1
  const id = new EntityID(ns, EntityType.Operation, originSite, originUser)

  const result = produce(operation as AppOperation, (draft) => {
    draft.context = clock
    draft.date = new Date().toISOString()
    draft.id = id
  })
  return result
}
