import { composeJsonReviver } from '../utils/composeJsonReviver'
import { isObject } from '../utils/isObject'
import { AnyObject } from '../utils/utility-types'
import { EntityID, EntityType, isIdentificable } from './Identificable'
import { VectorClock } from './VectorClock'

export type OperationID = EntityID<EntityType.Operation>

export enum OperationVerb {
  Boop = 'boop',
  Create = 'create',
  Resync = 'resync',
  Update = 'update',
  // ReParent = 're-parent',
  // Delete = 'delete',
  // Merge = 'merge',
  // Comment = 'comment',
  // Group = 'group',
  // Select = 'select',
  // Resolve = 'resolve',
  // Reject = 'reject',
  // Void = 'void',
  // Instance = 'instance',
  // Undo = 'undo',
  // Redo = 'redo',
  // SnapshotFrom = 'snapshot-from',
  // RestoreVersion = 'restore-version',
  // ResyncProposal = 'resync-proposal',
  // ResyncData = 'resync-data',
}

export type GenericOperationType<
  Verb extends OperationVerb = OperationVerb,
  Target extends EntityType = EntityType
> = `${Verb}:${Target}`

export interface Operation<
  T extends GenericOperationType = GenericOperationType,
  P = AnyObject | null
> {
  readonly context: VectorClock
  readonly date: string // ISO Timestamp
  readonly holderId: EntityID<EntityType.Project>
  readonly id: OperationID
  readonly latestSeq?: number
  readonly payload: P
  readonly seq: number | null
  readonly snapshotId: string
  readonly type: T
}

export type TypedOperation<P, T extends OperationVerb | keyof P = keyof P> = {
  [Key in keyof P]: Key extends GenericOperationType
    ? Operation<Key, P[Key]>
    : never
}[T extends OperationVerb ? Extract<GenericOperationType<T>, keyof P> : T]

export type OperationReducer<S, P> = (
  state: S,
  operation: TypedOperation<P>,
) => S

const operationTypeRegexp = `^(?<verb>${Object.values(OperationVerb).join(
  '|',
)}):(?<target>${Object.values(EntityType).join('|')})$`

export function isOperationType<
  V extends OperationVerb | undefined,
  T extends EntityType | undefined
>(
  value: unknown,
  verb?: V,
  target?: T,
): value is GenericOperationType<
  V extends undefined ? OperationVerb : V,
  T extends undefined ? EntityType : T
> {
  if (typeof value !== 'string') {
    return false
  }
  const match = new RegExp(operationTypeRegexp, 'ig').exec(value)
  const matchedVerb = match?.groups?.['verb']
  const matchedType = match?.groups?.['target']
  return (
    !!matchedVerb &&
    !!matchedType &&
    (!verb || (!!verb && verb === matchedVerb)) &&
    (!target || (!!target && target === matchedType))
  )
}

export function isOperation(
  arg: unknown,
  shouldThrow = false,
): arg is Operation {
  try {
    if (!isObject(arg) || !isIdentificable(arg, EntityType.Operation)) {
      throw new TypeError(`Received malformed serialized operation`)
    }

    if (!isOperationType(arg.type)) {
      throw new TypeError(`Received operation with incorrect 'type'`)
    }

    if (!isObject(arg.context) || !Object.keys(arg.context).length) {
      throw new TypeError(`Received operation with invalid 'context'`)
    }

    if (typeof arg.date !== 'string') {
      throw new TypeError(`Received operation widh invalid 'date'`)
    }

    if (typeof arg.snapshotId !== 'string') {
      throw new TypeError(`Received operation widh invalid 'snapshotId'`)
    }

    if (
      typeof arg.payload !== 'undefined' &&
      !isObject(arg.payload) &&
      arg.payload !== null
    ) {
      // TODO: I really should check that payload itself is valid
      throw new TypeError(`Received operation with invalid 'payload'`)
    }

    return true
  } catch (ex) {
    if (shouldThrow) throw ex
    else return false
  }
}

export function operationToString(operation: Operation): string {
  if (typeof operation.id === 'undefined' || operation.id === null) {
    throw new TypeError('Cannot serialize an operation without id')
  }
  return JSON.stringify(operation)
}

export function operationFromString(str: string): Operation | Operation[] {
  const operationData = JSON.parse(
    str,
    composeJsonReviver([EntityID.jsonReviver]),
  ) as Operation

  if (Array.isArray(operationData)) {
    return operationData.map(
      (op: Operation) => (isOperation(op, true), Object.freeze(op)),
    )
  } else {
    void isOperation(operationData, true)

    return Object.freeze(operationData)
  }
}
