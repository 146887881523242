const DIGITS_B36 = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]

export type UserId = string
export type SiteId = string
export type SID = `${UserId}~${SiteId}`

const SITE_ID_RADIX = 36
const SITE_ID_LENGTH = 3
const MAX_SITE_ID = Number(SITE_ID_RADIX - 1)
  .toString(SITE_ID_RADIX)
  .repeat(SITE_ID_LENGTH)
const MAX_SITE_ID_NUMBER = Number.parseInt(MAX_SITE_ID, SITE_ID_RADIX)

export function buildSID(userId: UserId, site: number | SiteId): SID {
  return `${userId}~${
    typeof site === 'string' ? site : getSiteIdFromSiteNumber(site)
  }`
}

export function getSiteIdFromSiteNumber(siteNumber: number): SiteId {
  if (siteNumber < 0 || siteNumber > MAX_SITE_ID_NUMBER) {
    throw new RangeError(
      `Invalid siteNumber='${siteNumber}'. Must be in the range [0, ${MAX_SITE_ID_NUMBER}].`,
    )
  }
  return siteNumber.toString(SITE_ID_RADIX).padStart(SITE_ID_LENGTH, '0')
}

export function getSiteNumberFromSiteId(siteId: SiteId): number {
  if (siteId.length !== SITE_ID_LENGTH) {
    throw new RangeError(
      `Invalid SiteId='${siteId}', it must have length of ${SITE_ID_LENGTH}`,
    )
  }
  Array.from(siteId).forEach((digit) => {
    if (!DIGITS_B36.includes(digit)) {
      throw new TypeError(
        `Invalid character '${digit}' found in SiteId='${siteId}'. Must be [0-9, a-z].`,
      )
    }
  })
  return Number.parseInt(siteId, SITE_ID_RADIX)
}
