import { FC, useEffect } from 'react'
import { useCurrentResource } from '../components/useCurrentResource'
import { useSidebarState } from '../menus/SidebarStateProvider'
import LayoutEditorCanvas from './LayoutEditorCanvas'
import LayoutEditorComponentDetails from './LayoutEditorComponentDetails'
import LayoutEditorComponentTree from './LayoutEditorComponentTree'
import LayoutEditorToolbar from './LayoutEditorToolbar'

const LayoutEditor: FC = () => {
  const currentResource = useCurrentResource()
  const { isOpen, toggle } = useSidebarState()
  useEffect(() => {
    if (isOpen) {
      toggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('currentResource=', currentResource)
  return (
    <div className="flex flex-col h-full">
      <LayoutEditorToolbar />
      <div className="flex-auto flex">
        <LayoutEditorComponentTree />
        <LayoutEditorCanvas />
        <LayoutEditorComponentDetails />
      </div>
    </div>
  )
}

export default LayoutEditor
